import { combineReducers } from "redux";
import { IntlReducer as Intl } from "react-redux-multilingual";
import dataReducer from "./data";

const rootReducer = combineReducers({
  data: dataReducer,
  Intl
});

export default rootReducer;
