const postMainCategory = async (token, data) => {
  const req = await fetch(
    `${process.env.REACT_APP_API_MEBZONE}/maincategories`,
    {
      method: "POST",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json; charset= UTF-8",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    }
  );
  try {
    const result = await req;
    return result;
  } catch (error) {
    console.log("error", error);
  }
};

const getMainCategories = async (token = {}, page = 1, pageSize = 10) => {
  const req = await fetch(
    `${process.env.REACT_APP_API_MEBZONE}/maincategories?page=${page}&size=${pageSize}`,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
  try {
    const mainCategory = await req;
    return mainCategory;
  } catch (error) {
    console.log("error", error);
  }
};

const getProductReport = async (token = {},page = 1, pageSize = 10) => {
  const req = await fetch(
    `${process.env.REACT_APP_API_MEBZONE}/productReports?page=${page}&size=${pageSize}`,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
  try {
    const productReports = req;
    return productReports;
  } catch (error) {
    console.log("error", error);
  }
};

const getStoreReport = async (token = {},page = 1, pageSize = 10) => {
  const req = await fetch(
    `${process.env.REACT_APP_API_MEBZONE}/storeReports?page=${page}&size=${pageSize}`,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
  try {
    const productReports = req;
    return productReports;
  } catch (error) {
    console.log("error", error);
  }
};

const getProductCommentReport = async (token = {},page = 1, pageSize = 10) => {
  const req = await fetch(
    `${process.env.REACT_APP_API_MEBZONE}/productCommentReports?page=${page}&size=${pageSize}`,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
  try {
    const productReports = req;
    return productReports;
  } catch (error) {
    console.log("error", error);
  }
};

const postCategory = async (token, data) => {
  const req = await fetch(`${process.env.REACT_APP_API_MEBZONE}/categories`, {
    method: "POST",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json; charset= UTF-8",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(data),
  });
  try {
    const result = await req;
    return result;
  } catch (error) {
    console.log("error", error);
  }
};

const getCategories = async (token = {}, page = 1, pageSize = 10) => {
  console.log(token)
  const req = await fetch(
    `${process.env.REACT_APP_API_MEBZONE}/categories/filter?page=${page}&size=${pageSize}`,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
  try {
    const categoryList = await req;
    return categoryList;
  } catch (error) {
    console.log("error", error);
  }
};

const postSubCategory = async (token, data) => {
  const req = await fetch(
    `${process.env.REACT_APP_API_MEBZONE}/subcategories`,
    {
      method: "POST",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json; charset= UTF-8",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    }
  );
  try {
    const result = await req;
    return result;
  } catch (error) {
    console.log("error", error);
  }
};

const getSubCategories = async (token = {}, page = 1, pageSize = 10) => {
  const req = await fetch(
    `${process.env.REACT_APP_API_MEBZONE}/subcategories?page=${page}&size=${pageSize}`,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
  try {
    const subCategory = await req;
    return subCategory;
  } catch (error) {
    console.log("error", error);
  }
};

const updateMainCategory = async (token, id, data) => {
  const req = await fetch(
    `${process.env.REACT_APP_API_MEBZONE}/maincategories/${id}`,
    {
      method: "POST",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json; charset= UTF-8",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    }
  );
  try {
    const result = await req;
    return result;
  } catch (error) {
    console.log("error", error);
  }
};

const updateCategory = async (token, id, data) => {
  const req = await fetch(
    `${process.env.REACT_APP_API_MEBZONE}/categories/${id}`,
    {
      method: "POST",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json; charset= UTF-8",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    }
  );
  try {
    const result = await req;
    return result;
  } catch (error) {
    console.log("error", error);
  }
};

const updateSubCategory = async (token, id, data) => {
  const req = await fetch(
    `${process.env.REACT_APP_API_MEBZONE}/subcategories/${id}`,
    {
      method: "POST",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json; charset= UTF-8",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    }
  );
  try {
    const result = await req;
    return result;
  } catch (error) {
    console.log("error", error);
  }
};

const mainCategoryList = async (token = {}) => {
  const req = await fetch(
    `${process.env.REACT_APP_API_MEBZONE}/maincategories/public`,
    {
      headers: {
        // Authorization: "Bearer " + token,
      },
    }
  );
  try {
    const mainCategoryList = await req.json();
    return mainCategoryList;
  } catch (error) {
    console.log("error", error);
  }
};

const categoryList = async (token = {}) => {
  const req = await fetch(
    `${process.env.REACT_APP_API_MEBZONE}/categories/filter`,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
  try {
    const categoryList = await req.json();
    return categoryList;
  } catch (error) {
    console.log("error", error);
  }
};

export {
  getMainCategories,
  getCategories,
  getSubCategories,
  postMainCategory,
  postCategory,
  postSubCategory,
  updateMainCategory,
  updateCategory,
  updateSubCategory,
  mainCategoryList,
  categoryList,
  getProductReport,
  getStoreReport,
  getProductCommentReport
};
