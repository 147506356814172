import React, { Component } from "react";

export class noauth extends Component {
  render() {
    return (
      <div
        className="container"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <h4 className="mt-5" style={{ textTransform: "none" }}>
          Iltimos, boshqattan login qiling
        </h4>

        <a
          className="mt-2 btn btn-primary"
          href={`${process.env.REACT_APP_MEBZONE_UZ}`}
        >
          Ortga
        </a>
      </div>
    );
  }
}

export default noauth;
