import React, { Component, Fragment } from "react";
import { withTranslate } from "react-redux-multilingual";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Modal from "react-responsive-modal";
import Cropper from "react-cropper";
import ClipLoader from "react-spinners/ClipLoader";
import "cropperjs/dist/cropper.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import Select from "react-select";
import SimpleReactValidator from "simple-react-validator";
import bigPlus from "../../../assets/images/product-upload/preview.svg";
import plus from "../../../assets/images/product-upload/upload.svg";
import "../../settings/categories/still.scss";
import { getFitSize, readImageSize } from "../../common/crop";
import { Trash2 } from "react-feather";
import {
  postImage,
  categoryListById,
  subCategoryListById,
  getCategoryWithOnlyFilters,
  postProduct,
  postProductAttribute,
  isExistsFilters,
} from "../listingProducts/api";
import { mainCategoryList } from "../../fetch/categories";

export class Tabset_product extends Component {
  constructor(props) {
    super(props);
    this.attachPhotoRef = React.createRef();
    this.cropperRef = React.createRef();
    this.state = {
      open: false,
      blob: "",
      blobURL: "",
      openCrop: false,
      now: null,
      dummyfiles: [{ file: "", blobUrl: "" }],
      fileTypes: ["jpg", "jpeg", "png"],
      images: [],
      errmsg0: false,
      page: 0,
      token: "",
      storeId: null,
      isExisting: false,
      name_uz: "",
      name_ru: "",
      price: "",
      description_uz: "",
      description_ru: "",
      isAvailable: null,
      isDeliverable: null,
      mainCategoryOptions: [],
      selectedMainCategory: null,
      mainCategoryId: null,
      categoryOptions: [],
      selectedCategory: null,
      categoryId: null,
      subCategoryOptions: [],
      selectedSubCategory: null,
      subCategoryId: null,
      typeOne: [],
      typeTwo: [],
      typeThree: [],
      numfilters: new Map(),
      stringfilters: new Map(),
      boolfilters: new Map(),
      valueNumfilters: new Map(),
      valueStringfilters: new Map(),
      valueBoolfilters: new Map(),
      productId: "",
      attributes: [],
      errmsg2: false,
      disableBtn1: false,
      disableBtn2: false,
      isTherePic: false,
    };
    this.handleInputs = this.handleInputs.bind(this);
    this.handleNumFilter = this.handleNumFilter.bind(this);
    this.takeProductImgIds = this.takeProductImgIds.bind(this);
  }

  componentWillMount() {
    this.validator = new SimpleReactValidator({
      messages: {
        required: this.props.translate("Fill_the_gap"),
        numeric: this.props.translate("Should_be_number"),
        integer: this.props.translate("Should_be_integer")
      },
    });

    const token = localStorage.getItem("token");
    const storeId = Number(localStorage.getItem("storeId"));
    mainCategoryList(token).then((mlist) => {
      let newArray = [];
      mlist.map((m) => {
        newArray.push({ value: m.id, label: this.props.locale === "en" ? m.name_uz : m.name_ru });
      });
      this.setState({ mainCategoryOptions: newArray, token, storeId });
    });
  }

  //image upload with cropping

  openCropModal = () => {
    this.setState({ open: true });
  };
  onCloseModal = () => {
    this.setState({ open: false });
  };

  removeAttach = (index) => {
    const { dummyfiles } = this.state;
    dummyfiles[index].file = ""
    dummyfiles[index].blobUrl = ""
    this.setState({ dummyfiles, blobURL: "" })
    // if (dummyfiles.length > 1) {
    //   dummyfiles.splice(index, 1);
    //   this.setState({ dummyfiles, blobURL: "" });
    // } else {
    //   this.setState({ dummyfiles: [{ file: "", blobUrl: "" }], blobURL: "" });
    // }
  };

  handleAttach = (index, preview) => {
    this.setState({
      now: index,
      errmsg0: false,
      blobURL: preview,
      isTherePic: false,
    });
    const { dummyfiles } = this.state;
    let len = dummyfiles.length;
    let one = len - index;
    if (len < 4 && one === 1) {
      dummyfiles.push({ file: "", blobUrl: "" });
      this.setState({ dummyfiles });
      //this.attachPhotoRef.current.click();
    } else {
      this.attachPhotoRef.current.click();
    }
  };

  handleAttachComplete = (e, i) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    if (file) {
      var extension = file.name.split(".").pop().toLowerCase();
      var isSuccess = this.state.fileTypes.indexOf(extension) > -1;
    }
    if (isSuccess) {
      reader.onloadend = () => {
        const { dummyfiles, now } = this.state;
        dummyfiles[now].file = file;
        dummyfiles[now].blobUrl = reader.result;
        const blobURL = reader.result;
        this.setState({
          dummyfiles,
          blobURL,
        });
        this.attachPhotoRef.current.value = "";
      };
      reader.readAsDataURL(file);
    } else {
      console.log("error image");
      this.setState({ errmsg0: true });
    }
  };
  ///////////

  handleInputs(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  handleSingleSelect1 = (selectedMainCategory) => {
    this.setState({
      selectedMainCategory,
      selectedCategory: null,
      categoryId: null,
      selectedSubCategory: null,
      subCategoryId: null,
    });
    if (selectedMainCategory) {
      categoryListById(this.state.token, selectedMainCategory.value).then(
        (clist) => {
          let newArray = [];
          clist.map((m) => {
            newArray.push({ value: m.id, label: this.props.locale === "en" ? m.name_uz : m.name_ru });
          });

          this.setState({
            mainCategoryId: selectedMainCategory.value,
            categoryOptions: newArray,
          });
        }
      );
    }
  };

  handleSingleSelect2 = (selectedCategory) => {
    this.setState({
      selectedCategory,
      selectedSubCategory: null,
      subCategoryId: null,
    });
    if (selectedCategory) {
      subCategoryListById(this.state.token, selectedCategory.value).then(
        (slist) => {
          // console.log(slist);
          let newArray = [];
          slist.map((m) => {
            newArray.push({ value: m.id, label: this.props.locale === "en" ? m.name_uz : m.name_ru });
          });

          this.setState({
            categoryId: selectedCategory.value,
            subCategoryOptions: newArray,
          });
          isExistsFilters(this.state.categoryId).then((res) => {
            if (res.isExisting) {
              this.setState({ isExisting: res.isExisting });
              getCategoryWithOnlyFilters(
                this.state.token,
                this.state.categoryId
              ).then((flist) => {
                const typeOne = flist.filter((f) => f.dataTypeId === 1);
                const typeTwo = flist.filter((f) => f.dataTypeId === 2);
                const typeThree = flist.filter((f) => f.dataTypeId === 3);
                this.setState({
                  typeOne,
                  typeTwo,
                  typeThree,
                });
              });
            } else {
              this.setState({ isExisting: res.isExisting });
            }
          });
        }
      );
    }
  };

  handleSingleSelect3 = (selectedSubCategory) => {
    this.setState({ selectedSubCategory });
    if (selectedSubCategory) {
      this.setState({
        subCategoryId: selectedSubCategory.value,
      });
    }
  };

  takeProductImgIds = (pics) => {
    return Promise.all(
      pics.map((req) => {
        return postImage(req.file);
      })
    )
      .then((res) => res.map((el) => el.id))
      .catch((err) => {
        console.log(err);
      });
  };

  // from here, second tabset functions starts
  handleNumFilter(e, fid) {
    const { numfilters, valueNumfilters } = this.state;
    if (e.target.value !== "") {
      numfilters.set(fid, {
        filterId: fid,
        intValue: Number(e.target.value),
      });
      valueNumfilters.set(fid, e.target.value);
    } else {
      numfilters.delete(fid);
      valueNumfilters.delete(fid);
    }
    this.setState({
      numfilters,
      valueNumfilters,
      errmsg2: false,
    });
  }

  handleSingleSelectForFilter = (e, fid) => {
    const { stringfilters, valueStringfilters } = this.state;
    if (e) {
      stringfilters.set(fid, {
        filterId: fid,
        stringValue_ru: e.ru,
        stringValue_uz: e.uz
      });
      valueStringfilters.set(fid, e);
    } else {
      stringfilters.delete(fid);
      valueStringfilters.delete(fid);
    }
    this.setState({
      stringfilters,
      valueStringfilters,
      errmsg2: false,
    });
  };

  handleBoolfilter = (e, fid) => {
    const { boolfilters, valueBoolfilters } = this.state;
    boolfilters.set(fid, {
      filterId: fid,
      booleanValue: e.target.value == "true" ? true : false,
    });
    valueBoolfilters.set(fid, e.target.value);
    this.setState({
      boolfilters,
      valueBoolfilters,
      errmsg2: false,
    });
  };

  continueBtn = (e) => {
    e.preventDefault();
    const { dummyfiles } = this.state;
    const pics = dummyfiles.filter((el) => el.file != "");
    if (pics.length > 0) {
      if (this.validator.allValid()) {
        this.setState({ page: 1 });
      } else {
        this.validator.showMessages();
        this.forceUpdate();
      }
    } else {
      console.log("at least one pic should be uploaded");
      this.setState({ isTherePic: true });
    }
  };

  createProductWithoutAttributes = (e) => {
    e.preventDefault();
    const { dummyfiles } = this.state;
    const pics = dummyfiles.filter((el) => el.file != "");
    if (pics.length > 0) {
      if (this.validator.allValid()) {
        this.setState({ disableBtn1: true });
        this.takeProductImgIds(pics).then((imgIds) => {
          const {
            name_uz,
            name_ru,
            price,
            description_uz,
            description_ru,
            isAvailable,
            isDeliverable,
            mainCategoryId,
            categoryId,
            subCategoryId,
            storeId,
          } = this.state;
          postProduct(
            {
              name_uz,
              name_ru,
              price,
              description_uz,
              description_ru,
              isAvailable,
              isDeliverable,
              mainCategoryId,
              categoryId,
              subCategoryId,
              images: imgIds,
              storeId,
            },
            this.state.token
          ).then(async (res) => {
            if (res.status == 200) {
              setTimeout(() => {
                toast.success(this.props.translate("Successfully_added"));
                window.location.href = `${process.env.PUBLIC_URL}/product-list`;
                this.setState({ disableBtn1: false });
              }, 2000);
            } else {
              setTimeout(() => {
                this.setState({ disableBtn1: false });
              }, 2000);
              console.log("smth problem with post product without attributes");
            }
          });
        });
      } else {
        this.validator.showMessages();
        this.forceUpdate();
      }
    } else {
      console.log("at least one pic should be upload");
      this.setState({ isTherePic: true });
    }
  };

  createProductWithAttributes = async (e) => {
    e.preventDefault();
    await this.setState({
      attributes: [
        ...this.state.numfilters.values(),
        ...this.state.stringfilters.values(),
        ...this.state.boolfilters.values(),
      ],
    });

    if (this.state.attributes.length > 0) {
      this.setState({ disableBtn2: true });
      const { dummyfiles } = this.state;
      const pics = dummyfiles.filter((el) => el.file != "");
      this.takeProductImgIds(pics).then((imgIds) => {
        const {
          name_uz,
          name_ru,
          price,
          description_uz,
          description_ru,
          isAvailable,
          isDeliverable,
          mainCategoryId,
          categoryId,
          subCategoryId,
          storeId,
        } = this.state;
        postProduct(
          {
            name_uz,
            name_ru,
            price,
            description_uz,
            description_ru,
            isAvailable,
            isDeliverable,
            mainCategoryId,
            categoryId,
            subCategoryId,
            images: imgIds,
            storeId,
          },
          this.state.token
        ).then(async (res) => {
          if (res.status === 200) {
            const productRes = await res
              .clone()
              .json()
              .then((json) => {
                const finalAttributes = this.state.attributes.map((el) => ({
                  ...el,
                  productId: json.id,
                }));

                this.setState({
                  attributes: [...finalAttributes],
                });

                postProductAttribute(
                  this.state.attributes,
                  this.state.token
                ).then((res) => {
                  if (res.status === 200) {
                    setTimeout(() => {
                      toast.success(this.props.translate("Successfully_added"));
                      window.location.href = `${process.env.PUBLIC_URL}/product-list`;
                      this.setState({ disableBtn2: false });
                    }, 2000);
                  } else {
                    setTimeout(() => {
                      this.setState({ disableBtn2: false });
                    }, 2000);
                    console.log("smth problem with post product attributes");
                  }
                });
              });
          } else {
            setTimeout(() => {
              this.setState({ disableBtn2: false });
            }, 2000);
            console.log("smth with wrong post product");
          }
        });
      });
    } else {
      console.log("no attributes");
      this.setState({ errmsg2: true });
    }
  };

  render() {
    const { translate, locale } = this.props
    const { blobURL } = this.state;
    return (
      <Fragment>
        <Tabs
          selectedIndex={this.state.page}
          onSelect={(index) => {
            this.setState({ page: index });
          }}
        >
          <TabList className="nav nav-tabs tab-coupon">
            <Tab disabled={this.state.page == 1} className="nav-link">
              {translate("main_data")}
            </Tab>
            {this.state.isExisting && (
              <Tab disabled={this.state.page == 0} className="nav-link">
                {translate("additional_data")}
              </Tab>
            )}
          </TabList>
          <TabPanel>
            <div className="row product-adding">
              <div className="col-xl-5">
                <div className="add-product">
                  <div className="row">
                    <div
                      className="col-xl-9 xl-50 col-sm-6 col-9 px-0"
                      style={{
                        border: blobURL ? "1px dashed grey" : "",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={blobURL ? blobURL : bigPlus}
                        alt="item-main-img"
                        className="img-fluid image_zoom_1 blur-up lazyloaded"
                      />
                    </div>
                    <div className="col-xl-3 xl-50 col-sm-6 col-3">
                      <ul className="file-upload-product mt-2 pt-1">
                        {this.state.dummyfiles.map((file, i) => {
                          return (
                            <li className="mb-5" key={i}>
                              <div
                                className="box-input-file"
                                style={{
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  this.handleAttach(i, file.blobUrl)
                                }
                              >
                                {file.blobUrl ? (
                                  <img
                                    src={file.blobUrl}
                                    style={{
                                      width: 70,
                                      height: 70,
                                      objectFit: "cover",
                                    }}
                                    alt="dummy imgs"
                                  />
                                ) : (
                                  <img
                                    src={plus}
                                    style={{ width: 70, height: 70 }}
                                    alt="dummy imgs"
                                  />
                                )}
                              </div>
                              {file.blobUrl && (
                                <button
                                  onClick={() => this.removeAttach(i)}
                                  className="ml-2"
                                  style={{
                                    paddingTop: "9%",
                                    background: "none",
                                    border: "none",
                                    color: "#00a075",
                                    cursor: "pointer",
                                  }}
                                >
                                  <Trash2 size={22} />
                                </button>
                              )}
                            </li>
                          );
                        })}
                      </ul>
                      {this.state.errmsg0 && (
                        <p style={{ color: "red" }}>{translate("Wrong_format")}</p>
                      )}
                      {this.state.isTherePic && (
                        <p style={{ color: "red" }}>
                          {translate("You_have_to_upload_image_at_list_one")}
                        </p>
                      )}
                      <input
                        style={{ display: "none" }}
                        ref={this.attachPhotoRef}
                        type="file"
                        multiple={false}
                        accept="image/*"
                        onChange={(e) => {
                          this.handleAttachComplete(e);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-7">
                <div className="needs-validation add-product-form">
                  <div className="form form-label-center">
                    <div className="form-group mb-3 row">
                      <label className="col-xl-3 col-sm-4 mb-0">
                        {translate("Product_name")} :
                      </label>
                      <div className="col-xl-8 col-sm-7">
                        <input
                          type="text"
                          className="form-control"
                          name="name_uz"
                          placeholder={translate("In_uzbek")}
                          value={this.state.name_uz}
                          onChange={this.handleInputs}
                          onBlur={() =>
                            this.validator.showMessageFor("name_uz")
                          }
                        />
                        {this.validator.message(
                          "name_uz",
                          this.state.name_uz,
                          "required"
                        )}
                      </div>
                    </div>

                    <div className="form-group mb-3 row">
                      <label className="col-xl-3 col-sm-4 mb-0">
                        {translate("Product_name")} :
                      </label>
                      <div className="col-xl-8 col-sm-7">
                        <input
                          type="text"
                          className="form-control"
                          name="name_ru"
                          placeholder={translate("In_russian")}
                          value={this.state.name_ru}
                          onChange={this.handleInputs}
                          onBlur={() =>
                            this.validator.showMessageFor("name_ru")
                          }
                        />
                        {this.validator.message(
                          "name_ru",
                          this.state.name_ru,
                          "required"
                        )}
                      </div>
                    </div>

                    <div className="form-group mb-3 row">
                      <label className="col-xl-3 col-sm-4 mb-0">{translate("Price")} :</label>
                      <div className="col-xl-8 col-sm-7">
                        <input
                          type="text"
                          className="form-control mb-0"
                          name="price"
                          placeholder={translate("uzs")}
                          value={this.state.price}
                          onChange={this.handleInputs}
                          onBlur={() => this.validator.showMessageFor("price")}
                        />
                        {this.validator.message(
                          "price",
                          this.state.price,
                          "required|numeric|integer"
                        )}
                      </div>
                    </div>
                    <div className="form-group mb-3 row">
                      <label className="col-xl-3 col-sm-4 mb-0">
                        {translate("Product_description")} :
                      </label>
                      <div className="col-xl-8 col-sm-7">
                        <textarea
                          rows="4"
                          className="form-control"
                          name="description_uz"
                          placeholder={translate("In_uzbek")}
                          value={this.state.description_uz}
                          onChange={this.handleInputs}
                          onBlur={() =>
                            this.validator.showMessageFor("description_uz")
                          }
                        />
                        {this.validator.message(
                          "description_uz",
                          this.state.description_uz,
                          "required"
                        )}
                      </div>
                    </div>
                    <div className="form-group mb-3 row">
                      <label className="col-xl-3 col-sm-4 mb-0">
                        {translate("Product_description")} :
                      </label>
                      <div className="col-xl-8 col-sm-7">
                        <textarea
                          rows="4"
                          className="form-control"
                          name="description_ru"
                          placeholder={translate("In_russian")}
                          value={this.state.description_ru}
                          onChange={this.handleInputs}
                          onBlur={() =>
                            this.validator.showMessageFor("description_ru")
                          }
                        />
                        {this.validator.message(
                          "description_ru",
                          this.state.description_ru,
                          "required"
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="form">
                    <div className="form-group row">
                      <label className="col-xl-3 col-sm-4">{translate("isAvailable")} :</label>

                      <div className="col-xl-9 col-sm-8">
                        <div className="form-group m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                          <label className="d-block">
                            <input
                              className="radio_animated"
                              id="edo-ani1"
                              type="radio"
                              name="isAvailable"
                              checked={this.state.isAvailable === "true"}
                              value={true}
                              onChange={this.handleInputs}
                              onBlur={() =>
                                this.validator.showMessageFor("isAvailable")
                              }
                            />
                            {translate("Yes")}
                          </label>
                          <label className="d-block">
                            <input
                              className="radio_animated"
                              id="edo-ani2"
                              type="radio"
                              name="isAvailable"
                              checked={this.state.isAvailable === "false"}
                              value={false}
                              onChange={this.handleInputs}
                              onBlur={() =>
                                this.validator.showMessageFor("isAvailable")
                              }
                            // defaultChecked
                            />
                            {translate("No")}
                          </label>
                        </div>
                        {this.validator.message(
                          "isAvailable",
                          this.state.isAvailable,
                          "required"
                        )}
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-xl-3 col-sm-4">{translate("Delivery")} :</label>

                      <div className="col-xl-9 col-sm-8">
                        <div className="form-group m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                          <label className="d-block">
                            <input
                              className="radio_animated"
                              id="edo-ani1"
                              type="radio"
                              name="isDeliverable"
                              checked={this.state.isDeliverable === "true"}
                              value={true}
                              onChange={this.handleInputs}
                              onBlur={() =>
                                this.validator.showMessageFor("isDeliverable")
                              }
                            />
                            {translate("Yes")}
                          </label>
                          <label className="d-block">
                            <input
                              className="radio_animated"
                              id="edo-ani2"
                              type="radio"
                              name="isDeliverable"
                              checked={this.state.isDeliverable === "false"}
                              value={false}
                              onChange={this.handleInputs}
                              onBlur={() =>
                                this.validator.showMessageFor("isDeliverable")
                              }
                            />
                            {translate("No")}
                          </label>
                        </div>
                        {this.validator.message(
                          "isDeliverable",
                          this.state.isDeliverable,
                          "required"
                        )}
                      </div>
                    </div>

                    {/* category selection */}
                    <div className="form-group row">
                      <label className="col-xl-3 col-sm-4 mb-0">
                        {translate("Main_category")} :
                      </label>
                      <div className="col-xl-8 col-sm-7">
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          options={this.state.mainCategoryOptions}
                          placeholder={translate("Select_main_category")}
                          value={this.state.selectedMainCategory}
                          onChange={this.handleSingleSelect1}
                          isClearable="false"
                          isSearchable="true"
                          onBlur={() =>
                            this.validator.showMessageFor(
                              "selectedMainCategory"
                            )
                          }
                        />
                        {this.validator.message(
                          "selectedMainCategory",
                          this.state.selectedMainCategory,
                          "required"
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-xl-3 col-sm-4 mb-0">
                        {translate("Category")} :
                      </label>
                      <div className="col-xl-8 col-sm-7">
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          options={this.state.categoryOptions}
                          value={this.state.selectedCategory}
                          placeholder={translate("Select_category")}
                          onChange={this.handleSingleSelect2}
                          isClearable="false"
                          isSearchable="true"
                          onBlur={() =>
                            this.validator.showMessageFor("selectedCategory")
                          }
                        />
                        {this.validator.message(
                          "selectedCategory",
                          this.state.selectedCategory,
                          "required"
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-xl-3 col-sm-4">
                        {translate("Subcategory")} :
                      </label>
                      <div className="col-xl-8 col-sm-7">
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          options={this.state.subCategoryOptions}
                          value={this.state.selectedSubCategory}
                          placeholder={translate("Select_subcategory")}
                          onChange={this.handleSingleSelect3}
                          isClearable="false"
                          isSearchable="true"
                          onBlur={() =>
                            this.validator.showMessageFor("selectedSubCategory")
                          }
                        />
                        {this.validator.message(
                          "selectedSubCategory",
                          this.state.selectedSubCategory,
                          "required"
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="offset-xl-3 offset-sm-4">
                    {this.state.isExisting ? (
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={(e) => {
                          this.continueBtn(e);
                        }}
                      >
                        {translate("Continue")}
                      </button>
                    ) : (
                      <button
                        disabled={this.state.disableBtn1}
                        style={{
                          cursor: this.state.disableBtn1 ? "not-allowed" : "",
                        }}
                        type="submit"
                        className="btn btn-primary"
                        onClick={(e) => this.createProductWithoutAttributes(e)}
                      >
                        <span
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <ClipLoader
                            color={"#fff"}
                            loading={this.state.disableBtn1}
                            size={20}
                          />{" "}
                          <span style={{ marginLeft: "8px" }}>{translate("Create")}</span>
                        </span>
                      </button>
                    )}
                    <Link
                      to={`${process.env.PUBLIC_URL}/product-list`}
                      className="btn btn-light"
                    >
                      {translate("Cancel")}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </TabPanel>

          {this.state.isExisting && (
            <TabPanel>
              <form className="needs-validation user-add">
                <h4 style={{ textTransform: "none" }} >{translate("Select_filters")}</h4>

                {/* data type is number */}
                {this.state.typeOne.map((x, index) => {
                  return (
                    <div key={index} className="form-group row">
                      <label className="col-xl-3 col-md-4 text-capitalize">
                        {locale === 'en' ? x.name_uz : x.name_ru}
                      </label>
                      <div className="col-xl-8 col-md-7">
                        <input
                          className="form-control"
                          type="number"
                          placeholder={translate("Enter")}
                          value={this.state.valueNumfilters.get(x.id)}
                          onChange={(e) => {
                            this.handleNumFilter(e, x.id);
                          }}
                        />
                      </div>
                    </div>
                  );
                })}

                {/* data type is boolean */}
                {this.state.typeThree.map((x, index) => {
                  return (
                    <div key={index} className="row mt-4 mb-3">
                      <div className="col-xl-3 col-sm-4">
                        <label className="text-capitalize">{locale === "en" ? x.name_uz : x.name_ru}</label>
                      </div>
                      <div className="col-xl-9 col-sm-8">
                        <div className="form-group m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                          <label className="d-block">
                            <input
                              className="radio_animated"
                              type="radio"
                              checked={
                                this.state.valueBoolfilters.get(x.id) == "true"
                              }
                              name={x.name_ru}
                              value={true}
                              onChange={(e) => this.handleBoolfilter(e, x.id)}
                            />
                            {translate("Yes")}
                          </label>
                          <label className="d-block">
                            <input
                              className="radio_animated"
                              type="radio"
                              checked={
                                this.state.valueBoolfilters.get(x.id) == "false"
                              }
                              name={x.name_ru}
                              value={false}
                              onChange={(e) => this.handleBoolfilter(e, x.id)}
                            />
                            {translate("No")}
                          </label>
                        </div>
                      </div>
                    </div>
                  );
                })}

                {/* data type is string */}
                {this.state.typeTwo.map((x, index) => {
                  let options = [];
                  x.filterOptions.map((o) => {
                    options.push({ value: o.id, label: locale === "en" ? o.stringValue_uz : o.stringValue_ru, uz: o.stringValue_uz, ru: o.stringValue_ru });
                  });

                  return (
                    <div key={index} className="form-group row">
                      <label className="col-xl-3 col-md-4 text-capitalize ">
                        {locale === "en" ? x.name_uz : x.name_ru}
                      </label>
                      <div className="col-xl-8 col-sm-7">
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          options={options}
                          value={this.state.valueStringfilters.get(x.id)}
                          placeholder={translate("Select")}
                          onChange={(e) =>
                            this.handleSingleSelectForFilter(e, x.id)
                          }
                          isClearable="false"
                          isSearchable="true"
                        />
                      </div>
                    </div>
                  );
                })}

                <div className="offset-xl-3 offset-sm-4 mt-4">
                  {this.state.errmsg2 && (
                    <p style={{ color: "red" }}>
                      {translate("You_have_to_choose_filter_at_list_one")}
                    </p>
                  )}
                  <button
                    disabled={this.state.disableBtn2}
                    style={{
                      cursor: this.state.disableBtn2 ? "not-allowed" : "",
                    }}
                    type="submit"
                    className="btn btn-primary"
                    onClick={(e) => {
                      this.createProductWithAttributes(e);
                    }}
                  >
                    <span
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <ClipLoader
                        color={"#fff"}
                        loading={this.state.disableBtn2}
                        size={20}
                      />{" "}
                      <span style={{ marginLeft: "8px" }}>{translate("Create")}</span>
                    </span>
                  </button>
                  <button
                    type="button"
                    className="btn btn-light"
                    onClick={() => {
                      this.setState({ page: 0 });
                    }}
                  >
                    {translate("Back")}
                  </button>
                </div>
              </form>
            </TabPanel>
          )}
        </Tabs>
        <ToastContainer />
      </Fragment>
    );
  }
}


const mapStateToProps = (state, ownProps) => ({
  locale: state.Intl.locale,
});

export default connect(mapStateToProps)(withTranslate(Tabset_product));
