import React, { Component, Fragment } from "react";
import { withTranslate } from "react-redux-multilingual";
import { connect } from "react-redux";
import SimpleReactValidator from "simple-react-validator";
import Select from "react-select";
import Breadcrumb from "../../common/breadcrumb";
import moment from "moment";
import Modal from "react-responsive-modal";
import "react-toastify/dist/ReactToastify.css";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { ToastContainer, toast } from "react-toastify";
import ClipLoader from "react-spinners/ClipLoader";
import {
  getDataTypes,
  postFilter,
  getAllFilters,
  updateFilter,
} from "../../fetch/filters";
import "../categories/still.scss";

export class Filter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: "",
      myData: [],
      pages: null,
      loading: true,
      open: false,
      action: null,
      name_uz: "",
      name_ru: "",
      dataTypeOptions: [],
      selectedDataType: null,
      dataTypeId: null,
      id: null,
      index: null,
      times: [],
      disableBtn1: false,
      disableBtn2: false
    };
    this.handleInputs = this.handleInputs.bind(this);
    this.fetchData = this.fetchData.bind(this);
  }

  componentWillMount() {
    this.validator = new SimpleReactValidator({
      messages: {
        required: this.props.translate("Fill_the_gap"),
      },
    });
    const token = localStorage.getItem("token");
    getDataTypes().then((dataTypes) => {
      let newArray = [];
      dataTypes.map((d) => {
        newArray.push({ value: d.id, label: this.props.locale ==="en" ? d.name_uz : d.name_ru });
      });
      this.setState({ dataTypeOptions: newArray, token: token });
    });
  }

  fetchData(state, instance) {
    this.setState({ loading: true });
    // console.log(state.pageSize, state.page, state.sorted, state.filtered);

    getAllFilters(this.state.token, state.page + 1, state.pageSize).then(
      async (res) => {
        if (res.status == 200) {
          const totalLength = res.headers.get("totalLength");
          const pages = Math.ceil(totalLength / state.pageSize);
          this.setState({ pages });
          const list = await res
            .clone()
            .json()
            .then((ulist) => {
              this.setState({
                myData: ulist,
                loading: false,
              });
            });
        }
      }
    );
  }

  onOpenModal = (type) => {
    this.setState({
      open: true,
      action: type,
    });
  };

  onCloseModal = () => {
    this.setState({
      open: false,
      action: null,
      name_uz: "",
      name_ru: "",
      id: null,
      index: null,
      selectedDataType: null,
      dataTypeId: null,
      times: [],
    });
  };

  handleInputs(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  handleSingleSelect = (selectedDataType) => {
    this.setState({ selectedDataType });
    if (selectedDataType) {
      const { times } = this.state;
      if (times.length > 0) {
        times.map((el) => {
          el.dataTypeId = selectedDataType.value;
        });
      }
      this.setState({
        dataTypeId: selectedDataType.value,
        times,
      });
    }
  };

  addFilterBtn = (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      this.setState({disableBtn1: true})
      const { token, name_uz, name_ru, dataTypeId, times } = this.state;
      // const arr = [{ name, dataTypeId }, ...times];
      // console.log("arr", arr);

      // removing null values
      const finalArray = [{ name_uz, name_ru, dataTypeId }, ...times].filter(
        (el) => el.name_uz !== "" && el.name_ru !== ""
      );

      //console.log(finalArray);
      postFilter(token, finalArray).then(async (res) => {
        if (res.status === 200) {
          const r = await res.clone().json();
          this.setState({
            myData: [...r, ...this.state.myData],
          });
          setTimeout(() => {
            this.setState({ disableBtn1: false });
            this.onCloseModal();
            toast.success(this.props.translate("Successfully_added"));
          }, 2000);
        } else {
          console.log("wrong status with add filter post");
        }
      });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  updateFilterBtn = (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      this.setState({disableBtn2: true})
      const { token, name_uz, name_ru, dataTypeId, id } = this.state;
      updateFilter(token, id, { name_uz, name_ru, dataTypeId }).then(
        async (res) => {
          if (res.status === 200) {
            const r = await res.clone().json();
            const newData = this.state.myData.filter((d) => d.id != id);
            const sortedData = [r, ...newData].sort(function (a, b) {
              return a.id - b.id;
            });
            this.setState({
              myData: sortedData,
            });
            setTimeout(() => {
              this.setState({ disableBtn2: false });
              this.onCloseModal();
              toast.success(this.props.translate("Successfully_updated"));
            }, 2000);
          } else {
            console.log("wrong status with update filter post");
          }
        }
      );
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  handleLoopInputsUz = (e, index) => {
    e.preventDefault();
    const { times } = this.state;
    times[index].name_uz = e.target.value;
    this.setState({ times });
  };

  handleLoopInputsRu = (e, index) => {
    e.preventDefault();
    const { times } = this.state;
    times[index].name_ru = e.target.value;
    this.setState({ times });
  };

  removeItem = (e, index) => {
    e.preventDefault();
    const { times } = this.state;
    times.splice(index, 1);
    this.setState({
      times,
    });
  };

  render() {
    const { translate, locale} = this.props
    const { open } = this.state;
    const columns = [
      {
        Header: "ID",
        accessor: "id",
        style: {
          textAlign: "center",
        },
      },
      {
        Header: translate("name"),
        accessor: locale === "en" ? "name_uz" : "name_ru",
        style: {
          textAlign: "center",
        },
      },
      {
        Header: translate("Datatype"),
        id: "dataType",
        accessor: (row) => {
          return locale === "en" ? row.dataType.name_uz : row.dataType.name_ru;
        },
        style: {
          textAlign: "center",
        },
      },
      {
        id: "createdAt",
        Header: translate("Time_created"),
        accessor: (row) => {
          return moment(row.createdAt).format("DD/MM/YYYY");
        },
        style: {
          textAlign: "center",
        },
        filterable: false,
      },
      {
        id: "updatedAt",
        Header: translate("Time_updated"),
        accessor: (row) => {
          return moment(row.updateAt).format("DD/MM/YYYY");
        },
        style: {
          textAlign: "center",
        },
        filterable: false,
      },
      {
        Header: <b>Action</b>,
        Cell: (row) => (
          <div>
            <span
              onClick={() => {
                const chosen = this.state.dataTypeOptions.find(
                  (x) => x.value === row.original.dataTypeId
                );
                console.log(chosen);
                const index = this.state.dataTypeOptions.indexOf(chosen);
                this.setState({
                  id: row.original.id,
                  name_uz: row.original.name_uz,
                  name_ru: row.original.name_ru,
                  index: index,
                  selectedDataType: chosen,
                  dataTypeId: chosen.value,
                });
                this.onOpenModal("edit");
              }}
            >
              <i
                className="fa fa-pencil"
                style={{
                  width: 35,
                  fontSize: 20,
                  padding: 11,
                  color: "rgb(40, 167, 69)",
                }}
              ></i>
            </span>
          </div>
        ),
        style: {
          textAlign: "center",
          cursor: "pointer",
        },
        sortable: false,
        filterable: false,
      },
    ];

    return (
      <Fragment>
        <Breadcrumb title={translate("Filters")} parent={translate("Product_classifications")} />
        {/* <!-- Container-fluid starts--> */}
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <h5>{translate("Product_filters")}</h5>
                </div>
                <div className="card-body">
                  <div className="btn-popup pull-right">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => this.onOpenModal("add")}
                      data-toggle="modal"
                      data-original-title="test"
                      data-target="#exampleModal"
                    >
                      {translate("Add_filter")}
                    </button>
                    <Modal
                      open={open}
                      onClose={this.onCloseModal}
                      closeOnOverlayClick={false}
                    >
                      <div className="modal-header">
                        <h5
                          className="modal-title f-w-600"
                          id="exampleModalLabel2"
                        >
                          {this.state.action == "add"
                            ? translate("Add_filter")
                            : translate("Update_filter")}
                        </h5>
                      </div>
                      <div className="modal-body">
                        <form>
                          <div className="form-group">
                            <label className="col-form-label">
                              {translate("Select_filter_type")}
                            </label>

                            <Select
                              defaultValue={
                                this.state.action == "edit"
                                  ? this.state.dataTypeOptions[this.state.index]
                                  : ""
                              }
                              className="basic-single"
                              classNamePrefix="select"
                              name="color"
                              options={this.state.dataTypeOptions}
                              placeholder={translate("Datatype")}
                              onChange={this.handleSingleSelect}
                              isClearable="false"
                              isSearchable="true"
                              onBlur={() =>
                                this.validator.showMessageFor(
                                  "selectedDataType"
                                )
                              }
                            />
                            {this.validator.message(
                              "selectedDataType",
                              this.state.selectedDataType,
                              "required"
                            )}

                            <label className="col-form-label">
                              {translate("Filter_name")}:
                            </label>
                            <div className="form-row">
                              <div className="col">
                                <input
                                  type="text"
                                  name="name_uz"
                                  value={this.state.name_uz}
                                  placeholder={
                                    this.state.action == "edit"
                                      ? this.state.name_uz
                                      : translate("In_uzbek")
                                  }
                                  onChange={this.handleInputs}
                                  className="form-control"
                                  onBlur={() =>
                                    this.validator.showMessageFor("name_uz")
                                  }
                                />
                                {this.validator.message(
                                  "name_uz",
                                  this.state.name_uz,
                                  "required"
                                )}
                              </div>
                              <div className="col">
                                <input
                                  type="text"
                                  name="name_ru"
                                  value={this.state.name_ru}
                                  placeholder={
                                    this.state.action == "edit"
                                      ? this.state.name_ru
                                      : translate("In_russian")
                                  }
                                  onChange={this.handleInputs}
                                  className="form-control"
                                  onBlur={() =>
                                    this.validator.showMessageFor("name_ru")
                                  }
                                />
                                {this.validator.message(
                                  "name_ru",
                                  this.state.name_ru,
                                  "required"
                                )}
                              </div>
                              {this.state.action == "add" && (
                                <div className="col-2">
                                  {this.state.times.length < 4 ? (
                                    <button
                                      onClick={() => {
                                        this.setState({
                                          times: [
                                            ...this.state.times,
                                            {
                                              name_uz: "",
                                              name_ru: "",
                                              dataTypeId: this.state.dataTypeId,
                                            },
                                          ],
                                        });
                                      }}
                                      type="button"
                                      className="btn btn-primary text-center"
                                    >
                                      <i
                                        className="fa fa-plus"
                                        aria-hidden="true"
                                      />
                                    </button>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              )}
                            </div>

                            {this.state.times.map((el, index) => {
                              return (
                                <div key={index}>
                                  <label className="col-form-label">
                                  {translate("Filter_name")}:
                                  </label>
                                  <div className="form-row">
                                    <div className="col">
                                      <input
                                        type="text"
                                        placeholder={translate("In_uzbek")}
                                        className="form-control"
                                        value={el.name_uz}
                                        onChange={(e) =>
                                          this.handleLoopInputsUz(e, index)
                                        }
                                        onBlur={() =>
                                          this.validator.showMessageFor(
                                            "inputUz" + index
                                          )
                                        }
                                      />
                                      {this.validator.message(
                                        "inputUz" + index,
                                        this.state.times[index].name_uz,
                                        "required"
                                      )}
                                    </div>
                                    <div className="col">
                                      <input
                                        type="text"
                                        placeholder={translate("In_russian")}
                                        className="form-control"
                                        value={el.name_ru}
                                        onChange={(e) =>
                                          this.handleLoopInputsRu(e, index)
                                        }
                                        onBlur={() =>
                                          this.validator.showMessageFor(
                                            "inputRu" + index
                                          )
                                        }
                                      />
                                      {this.validator.message(
                                        "inputRu" + index,
                                        this.state.times[index].name_ru,
                                        "required"
                                      )}
                                    </div>
                                    <div className="col-2">
                                      <button
                                        onClick={(e) => {
                                          this.removeItem(e, index);
                                        }}
                                        type="button"
                                        className="btn btn-warning text-center"
                                      >
                                        <i
                                          className="fa fa-minus"
                                          aria-hidden="true"
                                        />
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </form>
                      </div>
                      <div className="modal-footer">
                        {this.state.action == "add" ? (
                          <button
                            type="button"
                            disabled={this.state.disableBtn1}
                            style={{
                            cursor: this.state.disableBtn1 ? "not-allowed" : "",
                            }}
                            className="btn btn-primary"
                            onClick={(event) => this.addFilterBtn(event)}
                          >
                            <span
                              style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                             }}
                            >
                            <ClipLoader
                             color={"#fff"}
                             loading={this.state.disableBtn1}
                             size={20}
                            />
                            <span className="ml-1">
                              {translate("Add")}

                            </span>
                            </span>
                          </button>
                        ) : (
                          <button
                            type="button"
                            disabled={this.state.disableBtn2}
                            style={{
                            cursor: this.state.disableBtn2 ? "not-allowed" : "",
                            }}
                            className="btn btn-primary"
                            onClick={(event) => this.updateFilterBtn(event)}
                          >
                           <span
                           style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                           }}
                           >
                           <ClipLoader
                             color={"#fff"}
                             loading={this.state.disableBtn2}
                             size={20}
                            />
                            <span className="ml-1">
                            {translate("Update")}
                            </span>
                           </span>
                          </button>
                        )}

                        <button
                          type="button"
                          className="btn btn-secondary"
                          onClick={() => this.onCloseModal("VaryingMdo")}
                        >
                          {translate("Cancel")}
                        </button>
                      </div>
                    </Modal>
                  </div>
                  <div className="clearfix"></div>
                  <div id="basicScenario" className="product-physical">
                    <ReactTable
                      manual
                      multiSelectOption={false}
                      defaultPageSize={10}
                      pages={this.state.pages}
                      data={this.state.myData}
                      columns={columns}
                      showPagination={true}
                      className="-striped -highlight"
                      onFetchData={this.fetchData}
                      filterable
                      previousText={translate("Previous")}
                      nextText={translate("Next")}
                      pageText={translate("Page")}
                      rowsText={translate("Rows")}
                    />
                    <ToastContainer />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Container-fluid Ends--> */}
      </Fragment>
    );
  }
}


const mapStateToProps = (state) => ({
  locale: state.Intl.locale,
});
export default connect(mapStateToProps)(withTranslate(Filter));
