import React, { Component, Fragment } from "react";
import Breadcrumb from "./common/breadcrumb";
import {
  Box,
  MessageSquare,
  Users,
  ShoppingCart,
} from "react-feather";
import CountUp from "react-countup";
import { Chart } from "react-google-charts";
import CanvasJSReact from "../assets/canvas/canvasjs.react";
import { withTranslate } from "react-redux-multilingual";

import { Pie, Doughnut, Bar, Line } from "react-chartjs-2";
import {
  pieOptions,
  doughnutOption,
  lineOptions,
  buyOption,
  employeeData,
  employeeOptions,
} from "../constants/chartData";
// image impoer
import { getStatistics } from "./fetch/users";
import { connect } from "react-redux";
var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

export class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      totalProducts: "",
      totalStores: "",
      totalComments: "",
      totalCustomers: "",
      users: "",
      products: "",
      productComments: "",
    };
  }

  componentWillMount() {
    getStatistics().then((res) => {
      console.log("stats", res);
      const {
        totalComments,
        totalCustomers,
        totalProducts,
        totalStores,
        users,
        products,
        productComments,
      } = res;
      this.setState({
        totalComments,
        totalCustomers,
        totalProducts,
        totalStores,
        users,
        products,
        productComments,
      });
    });
  }

  render() {
    const { users, products } = this.state;
    const {translate, locale} = this.props
    return (
      <Fragment>
        <Breadcrumb title={translate("Statistics")} parent={translate("Statistics")} />
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-3 col-md-6 xl-50">
              <div className="card o-hidden widget-cards">
                <div className="bg-warning card-body">
                  <div className="media static-top-widget row">
                    <div className="icons-widgets col-4">
                      <div className="align-self-center text-center">
                        <ShoppingCart className="font-warning" />
                      </div>
                    </div>
                    <div className="media-body col-8">
                      <span className="m-0">{translate("Number_of_sellers")}</span>
                      <h3 className="mb-0">
                        <CountUp
                          className="counter"
                          end={Number(this.state.totalStores)}
                        />
                        {/* <small> This Month</small> */}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6 xl-50">
              <div className="card o-hidden  widget-cards">
                <div className="bg-secondary card-body">
                  <div className="media static-top-widget row">
                    <div className="icons-widgets col-4">
                      <div className="align-self-center text-center">
                        <Box className="font-secondary" />
                      </div>
                    </div>
                    <div className="media-body col-8">
                      <span className="m-0">{translate("Number_of_products")}</span>
                      <h3 className="mb-0">
                        <CountUp
                          className="counter"
                          end={Number(this.state.totalProducts)}
                        />
                        {/* <small> This Month</small> */}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6 xl-50">
              <div className="card o-hidden widget-cards">
                <div className="bg-primary card-body">
                  <div className="media static-top-widget row">
                    <div className="icons-widgets col-4">
                      <div className="align-self-center text-center">
                        <MessageSquare className="font-primary" />
                      </div>
                    </div>
                    <div className="media-body col-8">
                      <span className="m-0">{translate("Number_of_comments")}</span>
                      <h3 className="mb-0">
                        <CountUp
                          className="counter"
                          end={Number(this.state.totalComments)}
                        />
                        {/* <small> This Month</small> */}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6 xl-50">
              <div className="card o-hidden widget-cards">
                <div
                  className="card-body"
                  style={{ backgroundColor: "#f59292", color: "#fff" }}
                >
                  <div className="media static-top-widget row">
                    <div className="icons-widgets col-4">
                      <div className="align-self-center text-center">
                        <Users className="font-danger" />
                      </div>
                    </div>
                    <div className="media-body col-8">
                      <span className="m-0">{translate("Number_of_users")}</span>
                      <h3 className="mb-0">
                        <CountUp
                          className="counter"
                          end={Number(this.state.totalCustomers)}
                        />
                        {/* <small> This Month</small> */}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 xl-100">
              <div className="card">
                <div className="card-header">
                  <h5>{translate("Last_added_users")}</h5>
                </div>
                <div className="card-body">
                  <div className="user-status table-responsive latest-order-table">
                    <table className="table table-bordernone">
                      <thead>
                        <tr>
                          <th scope="col">N</th>
                          <th scope="col">{translate("User_name")}</th>
                          <th scope="col">{translate("Type_user")}</th>
                          <th scope="col">{translate("email")}</th>
                          <th scope="col">{translate("phone")}</th>
                          <th scope="col">{translate("gender")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {users ? (
                          users.map((user, index) => {
                            return (
                              <tr key={index}>
                                <td>{user.id}</td>
                                <td>
                                  {user.name} {user.surname}
                                </td>
                                <td className="font-primary">
                                  {locale === "en" ? user.roles[0].name_uz : user.roles[0].name_ru}
                                </td>

                                <td className="font-primary">{user.email}</td>
                                <td>{user.phone}</td>
                                <td>
                                  {user.gender ? (locale === "en" ? user.gender.name_uz : user.gender.name_ru) : ""}
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td>..</td>
                            <td>...</td>
                            <td>...</td>
                            <td>...</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 xl-100">
              <div className="card">
                <div className="card-header">
                  <h5>{translate("Last_added_products")}</h5>
                </div>
                <div className="card-body">
                  <div className="user-status table-responsive latest-order-table">
                    <table className="table table-bordernone">
                      <thead>
                        <tr>
                          <th scope="col">N</th>
                          <th scope="col">{translate("Product_name")}</th>
                          <th scope="col">{translate("Store")}</th>
                          <th scope="col">{translate('Price')}</th>
                          <th scope="col">{translate("Rating")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {products ? (
                          products.map((product, index) => {
                            return (
                              <tr key={index}>
                                <td>{product.id}</td>
                                <td>{locale === "en" ? product.name_uz: product.name_ru} </td>
                                <td>{product.store.brandname}</td>
                                <td>{product.price} UZS </td>
                                <td>{product.rate}</td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td>..</td>
                            <td>...</td>
                            <td>...</td>
                            <td>...</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
// javascript:void(0)
const mapStateToProps = (state, ownProps) => ({
  locale: state.Intl.locale,
});


export default  connect(mapStateToProps)(  withTranslate(Dashboard));
