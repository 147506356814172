import {
  Home,
  Box,
  DollarSign,
  Tag,
  Clipboard,
  Camera,
  AlignLeft,
  UserPlus,
  Users,
  Chrome,
  BarChart,
  Settings,
  Archive,
  LogIn,
  ShoppingCart,
  FileText,
  MessageSquare,
  Activity,
  AlertTriangle
} from "react-feather";

export const MENUITEMS = [
  {
    path: "/dashboard",
    title: "Статистика",
    title_uz: "Statistika",
    icon: Activity,
    type: "link",
    badgeType: "primary",
    active: false,
    role: 1,
  },

  {
    title: "Mагазины",
    title_uz: "Do'konlar",
    path: "/stores",
    icon: ShoppingCart,
    type: "link",
    active: false,
    role: 1,
  },

  {
    title: "Продукты",
    title_uz: "Mahsulotlar",
    path: "/allproducts",
    icon: Box,
    type: "link",
    active: false,
    role: 1,
  },

  {
    title: "Пользователи",
    title_uz: "Foydalanuvchilar",
    path: "/users-list",
    type: "link",
    icon: UserPlus,
    active: false,
    role: 1,
  },

  // {
  //   title: "Content management",
  //   path: "/users/list-user",
  //   type: "sub",
  //   icon: FileText,
  //   active: false,
  //   role: 1,
  //   children: [
  //     {
  //       title: "Pages",
  //       type: "sub",
  //       active: false,
  //       children: [
  //         {
  //           path: "/products/physical/sub-category",
  //           title: "Category",
  //           type: "link",
  //         },
  //         {
  //           path: "/products/physical/sub-category",
  //           title: "Sub Category",
  //           type: "link",
  //         },
  //         {
  //           path: "/products/physical/product-list",
  //           title: "Product List",
  //           type: "link",
  //         },
  //         {
  //           path: "/products/physical/product-detail",
  //           title: "Product Detail",
  //           type: "link",
  //         },
  //         {
  //           path: "/products/physical/add-product",
  //           title: "Add Product",
  //           type: "link",
  //         },
  //       ],
  //     },
  //     {
  //       title: "Ads",
  //       type: "sub",
  //       active: false,
  //       children: [
  //         {
  //           path: "/products/digital/digital-category",
  //           title: "Category",
  //           type: "link",
  //         },
  //         {
  //           path: "/products/digital/digital-sub-category",
  //           title: "Sub Category",
  //           type: "link",
  //         },
  //         {
  //           path: "/products/digital/digital-product-list",
  //           title: "Product List",
  //           type: "link",
  //         },
  //         {
  //           path: "/products/digital/digital-add-product",
  //           title: "Add Product",
  //           type: "link",
  //         },
  //       ],
  //     },
  //   ],
  // },

  {
    title: "Cортировка товаров",
    title_uz: "Mahsulotlarni toifalash",
    path: "/users/list-user",
    type: "sub",
    icon: Settings,
    active: false,
    role: 1,
    children: [
      {
        path: "/main-category",
        title: "Главная категория",
        title_uz: "Asosiy toifa",
        type: "link",
      },
      {
        path: "/category",
        title: "Категория",
        title_uz: "Toifa",
        type: "link",
      },
      {
        path: "/sub-category",
        title: "Подкатегория",
        title_uz: "Pastki toifa",
        type: "link",
      },
      {
        path: "/filters",
        title: "Фильтры",
        title_uz: "Filterlar",
        type: "link",
      },
      {
        path: "/filter-options",
        title: "Параметры фильтра",
        title_uz: "Filter parametrlari",
        type: "link",
      },
    ],
  },
  {
    title: "Жалобы",
    title_uz: "Shikoyatlar",
    path: "/users/list-user",
    type: "sub",
    icon: AlertTriangle,
    active: false,
    role: 1,
    children: [
      {
        path: "/porduct-report",
        title: "Жалобы на продукт",
        title_uz: "Mahsulot shikoyatlari",
        type: "link",
      },
      {
        path: "/product-comment-report",
        title: "Жалобы на комментарии к продукту",
        title_uz: "Mahsulot izoh shikoyatlari",
        type: "link",
      },
      {
        path: "/store-report",
        title: "Жалобы на магазины",
        title_uz: "Dukon shikoyatlari",
        type: "link",
      },
      
    ],
  },

  // {
  //   title: "Finance",
  //   path: "/users/list-user",
  //   type: "link",
  //   icon: DollarSign,
  //   active: false,
  //   role: 1,
  // },

  {
    title: "Товары",
    title_uz: "Tovarlar",
    path: "/users/list-user",
    type: "sub",
    icon: Settings,
    active: false,
    role: 2,
    children: [
      {
        path: "/product-list",
        title: "Список товаров",
        title_uz: "Tovarlar ro'yxati",
        type: "link",
      },
      {
        path: "/create-product",
        title: "Добавить товар",
        title_uz: "Tovar qo'shish",
        type: "link",
      },
    ],
  },

  // {
  //   title: "Отзывы",
  //   path: "/users/list-user",
  //   type: "link",
  //   icon: MessageSquare,
  //   active: false,
  //   role: 2,
  // },

  {
    title: "Мои магазины",
    title_uz: "Mening do'konlarim",
    path: "/ownstores",
    type: "link",
    icon: ShoppingCart,
    active: false,
    role: 2,
  },
];
