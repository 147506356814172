import React, { Component, Fragment } from "react";
import { withTranslate } from "react-redux-multilingual";
import { connect } from "react-redux";
import Breadcrumb from "../../common/breadcrumb";
import { Edit, Search } from "react-feather";
import { getStoreProducts, getFilteredProducts } from "./api";
import bigPlus from "../../../assets/images/bigplus.svg";
import { Link } from "react-router-dom";
import Select from "react-select";

export class Product_list extends Component {
  constructor(props) {
    super(props);
    this.state = {
      productList: [],
      isBlocked: null,
      statusId: null,
      selectedBlockValue: null,
      selectedPublishValue: null

    };
  }

  componentWillMount() {
    const token = localStorage.getItem("token");
    const storeId = Number(localStorage.getItem("storeId"));
    getStoreProducts(token, storeId).then((res) => {
      this.setState({ productList: res });
    });
  }

   handleSelectBlocked = async (selected) => {
    await this.setState({isBlocked: selected.value, selectedBlockValue: selected})
    const token = localStorage.getItem("token");
    const storeId = Number(localStorage.getItem("storeId"));
    getFilteredProducts(token, storeId, this.state.isBlocked, this.state.statusId).then((res) => {
      this.setState({ productList: res });
    });
   }

   handleSelectPublished = async (selected) => {
    await this.setState({statusId: selected.value, selectedPublishValue: selected})
    const token = localStorage.getItem("token");
    const storeId = Number(localStorage.getItem("storeId"));
    getFilteredProducts(token, storeId, this.state.isBlocked, this.state.statusId).then((res) => {
      this.setState({ productList: res });
    });
   }

   clearBtn = (e) => {
     e.preventDefault()
     this.setState({selectedBlockValue: null, selectedPublishValue: null})
     const token = localStorage.getItem("token");
     const storeId = Number(localStorage.getItem("storeId"));
     getStoreProducts(token, storeId).then((res) => {
       this.setState({ productList: res });
     });
   }
   

  render() {
    const { translate, locale } = this.props
    const { productList } = this.state;
    if (productList.length === 0) {
      return (
        <Fragment>
          <Breadcrumb title={translate("Product_list")} parent={translate("items")} />
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-header">
                    <p className="m-2">{translate("Filters")}</p>
                    <div style={{display: "flex", flexWrap: "wrap"}}>
                    <div style={{width: "250px"}}>
                      <Select
                          className="basic-single m-2"
                          classNamePrefix="select"
                          options={[{ value: true, label: translate("Blocked") }, { value: false, label: translate("Unblocked") }]}
                          placeholder={translate("Select_filters")}
                          value={this.state.selectedBlockValue}
                          onChange={this.handleSelectBlocked}
                          isSearchable="true"
                        />
                    </div>
                    <div style={{width: "250px"}}>
                        <Select
                          className="basic-single m-2"
                          classNamePrefix="select"
                          options={[{ value: 2, label: translate("Published") }, { value: 1, label: translate("Unpublished") }]}
                          placeholder={translate("Select_filters")}
                          value={this.state.selectedPublishValue}
                          onChange={this.handleSelectPublished}
                          isSearchable="true"
                        />
                    </div>
                        <button className="btn btn-primary m-2" onClick={this.clearBtn}>{translate("clear_filter")}</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-header">
                    <p>{translate("you_have_not_created_product_yet")}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <Breadcrumb title={translate("Product_list")} parent={translate("items")} />
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-header">
                    <p className="m-2">{translate("Filters")}</p>
                    <div style={{display: "flex", flexWrap: "wrap"}}>
                    <div style={{width: "250px"}}>
                      <Select
                          className="basic-single m-2"
                          classNamePrefix="select"
                          options={[{ value: true, label: translate("Blocked") }, { value: false, label: translate("Unblocked") }]}
                          placeholder={translate("Select_filters")}
                          value={this.state.selectedBlockValue}
                          onChange={this.handleSelectBlocked}
                          isSearchable="true"
                        />
                    </div>
                    <div style={{width: "250px"}}>
                        <Select
                          className="basic-single m-2"
                          classNamePrefix="select"
                          options={[{ value: 2, label: translate("Published") }, { value: 1, label: translate("Unpublished") }]}
                          placeholder={translate("Select_filters")}
                          value={this.state.selectedPublishValue}
                          onChange={this.handleSelectPublished}
                          isSearchable="true"
                        />
                    </div>
                        <button className="btn btn-primary m-2" onClick={this.clearBtn}>{translate("clear_filter")}</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row products-admin ratio_asos">
              {this.state.productList.map((product, i) => {
                return (
                  <div className="col-xl-3 col-sm-6 make50" key={i}>
                    <div className="card">
                      <div className="products-admin">
                        <div className="card-body product-box">
                          <div className="img-wrapper">
                            <div className="lable-block">
                              <span className="lable3 make50span">
                                {locale === "en" ? product.condition.name_uz : product.condition.name_ru}
                              </span>
                            </div>
                            <div className="front">
                              <a className="bg-size">
                                <img
                                  className="img-fluid blur-up bg-img lazyloaded"
                                  src={
                                    product.images
                                      ? product.images[0]
                                        ? product.images[0].imageUrl
                                        : bigPlus
                                      : bigPlus
                                  }
                                />
                              </a>
                              <div className="product-hover">
                                <ul>
                                  <li>
                                    <Link
                                      to={`${process.env.PUBLIC_URL}/product-detail/${product.id}`}
                                      className="btn"
                                      style={{ color: "#000" }}
                                    >
                                      <Search className="deleteBtn" />
                                    </Link>
                                  </li>
                                  <li>
                                    <Link
                                      to={`${process.env.PUBLIC_URL}/update-product/${product.id}`}
                                      className="btn"
                                      style={{ color: "#000" }}
                                    >
                                      <Edit className="editBtn" />
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="product-detail">
                            <h6>{locale === "en" ? product.name_uz : product.name_ru}</h6>
                            <h4 className="make50h4">{product.price} UZS</h4>

                            {product.statusId === 2 ? (
                              <p
                                style={{
                                  background: "#F1F4FB",
                                  width: "50%",
                                  textAlign: "center",
                                  fontWeight: 700,
                                }}
                                className="mt-4 mb-2 make50p"
                              >
                                {translate("Published")}
                              </p>
                            ) : (
                              <p
                                style={{
                                  background: "#F1F4FB",
                                  color: "#f36f6f",
                                  width: "50%",
                                  textAlign: "center",
                                  fontWeight: 700,
                                }}
                                className="mt-4 mb-2 make50p"
                              >
                                {translate("Unpublished")}
                              </p>
                            )}
                            {product.isBlocked == 1 && (
                              <p
                                className="make50p"
                                style={{
                                  background: "#F1F4FB",
                                  width: "50%",
                                  textAlign: "center",
                                  fontWeight: 700,
                                  color: "#f36f6f",
                                }}
                              >
                                {translate("Blocked")}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </Fragment>
      );
    }
  }
}


const mapStateToProps = (state, ownProps) => ({
  locale: state.Intl.locale,
});

export default connect(mapStateToProps)(withTranslate(Product_list));
