var module = {
  en: {
    locale: 'en',
    messages: {
      Product_report: "Mahsulot shikoyatlari",
      Store_report: "Dukon shikoyatlari",
      Product_comment_report: "Mahsulot izoh shikoyatlari",
      report_type: "Shikoyat turi",
      product: "Mahsulot",
      store: "Do'kon nomi",
      user_id: "Foydalanuvchi",
      user_comment: "Foydalanuvchi izohi",
      Statistics: "Statistika",
      Number_of_sellers: "Do'konlar soni",
      Number_of_products: "Mahsulotlar soni",
      Number_of_comments: "Sharhlar soni",
      Number_of_users: "Foydalanuvchilar soni",
      Last_added_users: "Oxirgi qo'shilgan foydalanuvchilar",
      Last_added_products: "Yaqinda qo'shilgan mahsulotlar",
      User_name: "Ism",
      Type_user: "Foydalanuvchi turi",
      email: "E-pochta",
      phone: "Telefon raqami",
      gender: "Jinsi",
      Product_name: "Mahsulot nomi",
      Store: "Do'kon",
      Price: "Narxi",
      Rating: "Baholash",
      List_of_products: "Mahsulotlar ro'yxati",
      Products: "Mahsulotlar",
      List_of_users: "Foydalanuvchilar ro'yxati",
      users: "Foydalanuvchilar",
      Info_about_users: "Foydalanuvchi ma'lumotlari",
      Surname: "Familiya",
      Role: "Rol",
      Address: "Manzil",
      Status: "Holati",
      Product_list: "Mahsulotlar ro'yxati",
      items: "Mahsulotlar",
      Blocked: "Bloklangan",
      Unblocked: "Bloklanmagan",
      Published: "Nashr qilingan",
      Unpublished: "Nashr qilinmagan",
      you_have_not_created_product_yet: "Siz hali mahsulot yaratmagansiz ...",
      Back: "Ortga",
      Info_about_product: "Mahsulot haqida ma'lumot",
      To_publish: "Nashr qilish",
      Cancel_publish: "Nashrdan chiqarish",
      Active: "Faol",
      Disactive: "Faol emas",
      Delivery: "Yetkazib berish",
      isAvailable: "Sotuvda mavjud",
      Yes: "Ha",
      No: "Yo'q",
      Manufacturer: "Ishlab chiqaruvchi",
      Product_description: "Xususiyatlari va tavsifi",
      Main_category: "Asosiy toifa",
      Main_category_products: "Asosiy mahsulot toifalari",
      name: "Nomi",
      Description: "Tavsif",
      wallpaper: "Fon rasmi",
      Time_created: "Qo'shilgan vaqti",
      Time_updated: "Tahrir qilingan vaqti",
      add_main_category: "Asosiy toifani qo'shish",
      update_main_category: "Asosiy toifani yangilash",
      main_category_name: "Asosiy toifaning nomi",
      In_uzbek: "O'zbek tilida",
      In_russian: "Rus tilida",
      Add: "Qo'shish",
      Cancel: "Bekor qilish",
      Update: "Yangilash",
      Previous: "Oldingi",
      Next: "Keyingi",
      Page: "Sahifa",
      Rows: "qator",
      Product_classifications: "Mahsulotlarni toifalash",
      Report_classifications: "Shikoyatlar",
      Filters: "Filterlar",
      Select_main_category: "Asosiy toifani tanlang",
      Select_filters: "Filterlarni tanlang",
      Sub_categories: "Pastki toifalar",
      sub: "pastki...",
      Product_category: "Mahsulot toifalari",
      add_category: "Kategoriya qo'shish",
      update_category: "Kategoriyani yangilash",
      category_name: "Kategoriya nomi",
      Category: "Toifa",
      Subcategory: "Pastki toifa",
      Subcategory_products: "Mahsulotlarning kichik toifasi",
      add_subcategory: "Pastki toifani qo'shish",
      update_subcategory: "Pastki toifani yangilash",
      Select_category: "Kategoriyani tanlang",
      Subcategory_name: "Pastki toifaning nomi",
      Datatype: "Turi",
      Product_filters: "Mahsulot filterlari",
      Add_filter: "Filter qo'shish",
      Update_filter: "Filterni yangilash",
      Filter_name: "Filter nomi",
      Select_filter_type: "Filter turini tanlang",
      Filter: "Filter",
      Option: "Variant",
      Filter_parameters: "Filter parametrlari",
      Add_filter_parameter: "Filter parametrni qo'shish",
      Update_filter_parameter: "Filter parametrini yangilash",
      Filter_parameter_name: "Filter parametrini nomi",
      Joined: "Qo'shilgan sanasi",
      Last_login: "So'ngi faollik",
      Mebzone_admin_panel: "Mebzone boshqaruv paneli",
      Mebzone_seller_panel: "Mebzone sotuvchi paneli",
      To_block: "Bloklash",
      Cancel_block: "Blokdan chiqarish",
      Comments: "Sharhlar",
      Store_list: "Do'konlar ro'yxati",
      Stores: "Do'konlar",
      Region: "Viloyat",
      In_detail: "Batafsil",
      Activate: "Faollashtirish",
      Disactivate: "O'chirish",
      No_products_yet: "Hali mahsulot qo'shilmagan",
      Company_name: "Kompaniya nomi",
      Language: "Til",
      Uzbek: "O'zbekcha",
      Russian: "Ruscha",
      Log_out: "Chiqish",
      Login: "Kirish",
      Edit_user_profile: "Profilni tahrirlash",
      Successfully_added: "Muvaffaqiyatli yaratildi",
      Successfully_updated: "Muvaffaqiyatli yangilandi",
      Fill_the_gap: "Formani to'ldiring",
      Button_name: "Tugmani nomi",
      you_are_not_activated: "Faollashtirilmagan",
      My_stores: "Mening do'konlarim",
      add_product: "Tovar qo'shish",
      Products: "Tovarlar",
      main_data: "Asosiy ma'lumotlar",
      additional_data: "Qo'shimcha ma'lumotlar",
      uzs: "so'm",
      Select_subcategory: "Pastki toifani tanlang",
      Create: "Yaratish",
      Continue: "Davom etish",
      You_have_to_upload_image_at_list_one: "Siz kamida bitta rasm yuklashingiz kerak",
      Wrong_format: "Noto'g'ri format",
      You_have_to_choose_filter_at_list_one: "Siz kamida bitta filter tanlagan bo'lishingiz kerak!",
      Select: "Tanlang",
      Enter: "Kiriting",
      Update_product: "Mahsulotni tahrirlash",
      Update: "Tahrirlash",
      My_profile: "Mening profilim",
      you_have_only_one_store: "Sizda hozir faqat bitta do'kon mavjud. Qo'shimcha do'kon yaratish uchun profilingizga o'ting va do'kon yaratish bo'limida o'z do'koningizni yarating ...",
      Should_be_number: "Son kiriting",
      Are_you_sure_delete_this_picture: "Rasmni o'chirmoqchimisiz ?",
      Should_be_integer: "Iltimos, butun son kiriting",
      clear_filter: "Tozalash"

    }
  },
  ru: {
    locale: 'ru',
    messages: {
      Product_report: "Жалобы на продукт",
      Store_report: "Жалобы на магазины",
      Product_comment_report: "Жалобы на комментарии к продукту",
      report_type: "Тип жалобы",
      product: "Товар",
      store: "Название магазина",
      user_id: "Пользователь",
      user_comment: "Комментарий пользователя",
      Statistics: "Статистика",
      Number_of_sellers: "Количество магазинов",
      Number_of_products: "Количество продуктов",
      Number_of_comments: "Количество Отзывов",
      Number_of_users: "Количество пользователей",
      Last_added_users: "Последние добавленные пользователи",
      Last_added_products: "Последние добавленные товары",
      User_name: "Имя",
      Type_user: "Тип пользователя",
      email: "Электронная почта",
      phone: "Телефон номер",
      gender: "Пол",
      Product_name: "Название товара",
      Store: "Mагазин",
      Price: "Цена",
      Rating: "Рейтинг",
      List_of_products: "Список продуктов",
      Products: "Продукты",
      List_of_users: "Список пользователей",
      users: "Пользователи",
      Info_about_users: "Информация о пользователях",
      Surname: "Фамилия",
      Role: "Роль",
      Address: "Адрес",
      Status: "Статус",
      Product_list: "Список товаров",
      items: "Товары",
      Blocked: "Заблокировано",
      Unblocked: "Pазблокировано",
      Published: "Опубликовано",
      Unpublished: "Неопубликованные",
      you_have_not_created_product_yet: "Вы еще не создали продукт ...",
      Back: "Hазад",
      Info_about_product: "Информация о продукте",
      To_publish: "Публиковать",
      Cancel_publish: "Отменить публикацию",
      Active: "Aктивный",
      Disactive: "Hеактивный",
      Delivery: "Доставка",
      isAvailable: "В наличии",
      Yes: "Да",
      No: "Нет",
      Manufacturer: "Производитель",
      Product_description: "Характеристики и описание",
      Main_category: "Главная Категория",
      Main_category_products: "Главные категории товаров",
      name: "Название",
      Description: "Описание",
      wallpaper: "Фоновое фото",
      Time_created: "Время добавления",
      Time_updated: "Редактированное время",
      add_main_category: "Добавить главную категорию",
      update_main_category: "Обновить главную категорию",
      main_category_name: "Название главной категории",
      In_uzbek: "На узбекском",
      In_russian: "На русском",
      Add: "Добавить",
      Cancel: "Oтменить",
      Update: "Обновить",
      Previous: "Предыдущий",
      Next: "Следующий",
      Page: "Страница",
      Rows: "cтрочка",
      Product_classifications: "Cортировка товаров",
      Report_classifications: "Жалобы",
      Filters: "Фильтры",
      Select_main_category: "Выберите главную категорию",
      Select_filters: "Выберите фильтры",
      Sub_categories: "Подкатегории",
      sub: "под...",
      Product_category: "Категории товаров",
      add_category: "Добавить категорию",
      update_category: "Обновить категорию",
      category_name: "Название категории",
      Category: "Категория",
      Subcategory: "Подкатегория",
      Subcategory_products: "Подкатегория товаров",
      add_subcategory: "Добавить подкатегорию",
      update_subcategory: "Обновить подкатегорию",
      Select_category: "Выберите категорию",
      Subcategory_name: "Название подкатегории",
      Datatype: "Tип",
      Product_filters: "Фильтры товаров",
      Add_filter: "Добавить фильтр",
      Update_filter: "Обновить фильтр",
      Filter_name: "Название фильтр",
      Select_filter_type: "Выберите тип фильтрa",
      Filter: "Фильтр",
      Option: "Вариант",
      Filter_parameters: "Параметры Фильтра",
      Add_filter_parameter: "Добавить параметр фильтра",
      Update_filter_parameter: "Обновить параметр фильтра",
      Filter_parameter_name: "Название параметр фильтра",
      Joined: "Дата регистрации",
      Last_login: "Последняя активность",
      Mebzone_admin_panel: "Панель администратора Mebzone",
      Mebzone_seller_panel: "Панель продавца Mebzone",
      To_block: "Блокировать",
      Cancel_block: "Разблокировать",
      Comments: "Отзывы",
      Store_list: "Список магазинов",
      Stores: "Mагазины",
      Region: "Регион",
      In_detail: "Подробнее",
      Activate: "Активировать",
      Disactivate: "Деактивировать",
      No_products_yet: "Товаров пока нет",
      Company_name: "Название компании",
      Language: "Язык",
      Uzbek: "Узбекский",
      Russian: "Русский",
      Log_out: "Выйти",
      Login: "Войти",
      Edit_user_profile: "Редактировать профиль",
      Successfully_added: "Успешно добавлено",
      Successfully_updated: "Успешно обновлено",
      Fill_the_gap: "Заполните поле",
      Button_name: "Название кнопки",
      you_are_not_activated: "Вы не активированы",
      My_stores: "Мои магазины",
      add_product: "Добавить товар",
      Products: "Товары",
      main_data: "Oсновные данные",
      additional_data: "Дополнительная данные",
      uzs: "сум",
      Select_subcategory: "Выберите подкатегорию",
      Create: "Создать",
      Continue: "Продолжить",
      You_have_to_upload_image_at_list_one: "Вы должны загрузить хотя бы одно изображение",
      Wrong_format: "Hеправильный формат",
      You_have_to_choose_filter_at_list_one: "Вы должны выбрать по крайней мере один фильтр!",
      Select: "Выберите",
      Enter: "Bведите",
      Update_product: "Обновить товар",
      Update: "Обновить",
      My_profile: "Мой профиль",
      you_have_only_one_store: "В данный момент у вас есть один магазин. Чтобы создать новый магазин пройдите в раздел добавления магазина через ваш профиль и создайте новый магазин ...",
      Should_be_number: "Введите число",
      Are_you_sure_delete_this_picture: "Вы хотите удалить фото ?",
      Should_be_integer: "Пожалуйста, введите целое число",
      clear_filter: "Oчистить"
    }
  }
}


export default module