import React, { Component } from "react";
import man from "../../../assets/images/dashboard/man.png";
import { getOwnStore } from "../../fetch/stores";

export class User_panel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: "",
      storeId: "",
      storeInfo: "",
    };
  }

  componentWillMount() {
    const token = localStorage.getItem("token");
    const storeId = Number(localStorage.getItem("storeId"));
    if (storeId) {
      getOwnStore(token, storeId).then((info) => {
        this.setState({ storeInfo: info });
      });
    }
  }

  render() {
    return (
      <div>
        <div className="sidebar-user text-center">
          <div>
            <img
              className="img-60 rounded-circle lazyloaded blur-up"
              style={{ height: "60px", objectFit: "cover" }}
              src={
                this.state.storeInfo ? this.state.storeInfo.image.imageUrl : man
              }
              alt="user-pic"
            />
          </div>
          <h6 className="mt-3 f-14">
            {this.state.storeInfo ? this.state.storeInfo.brandname : "Admin"}
          </h6>
          {/* <p>general manager.</p> */}
        </div>
      </div>
    );
  }
}

export default User_panel;
