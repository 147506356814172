import React, {Component} from "react";
import ReactDOM from "react-dom";
import {Provider} from "react-redux";
import {IntlProvider} from 'react-redux-multilingual'
import translations from "./redux/constants/translations"
import store from "./redux/store";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import "./index.scss";
import App from "./components/app";
import {ScrollContext} from "react-router-scroll-4";

// Components
import Dashboard from "./components/dashboard";
import Login from "./components/auth/login";
import NoAuth from "./components/noAuth/noauth";

// Stores
import StoresList from "./components/stores/storesList";
import OwnStoresList from "./components/stores/ownStoresList";
import ActivateStore from "./components/stores/activateStore";

// Products
import Product_Table from "./components/products/listingProducts/product-table";
import Product_list from "./components/products/listingProducts/product-list";
import Product_detail from "./components/products/listingProducts/product-detail";
import CreateProduct from "./components/products/createProduct/create-product";
import UpdateProduct from "./components/products/updateProduct/update-product";

// Users
import Users_list from "./components/users/users-list";

// Settings
import MainCategory from "./components/settings/categories/mainCategory";
import Category from "./components/settings/categories/category";
import Sub_category from "./components/settings/categories/subCategory";
import Filters from "./components/settings/filters/filter";
import FilterOptions from "./components/settings/filters/filterOptions";

// Reports
import ProductReport from "./components/reports/productReport/productReport";
import StoreReport from "./components/reports/storeReport/storeReport";
import productCommentReport from "./components/reports/productComentReport/productCommentReport";

class Root extends Component {
    render() {
        return (
            <Provider store={store}>
                <IntlProvider translations={translations} locale='en'>
                    <BrowserRouter basename={"/"}>
                        <ScrollContext>
                            <Switch>
                                <Route
                                    exact
                                    path={`${process.env.PUBLIC_URL}/`}
                                    component={Login}
                                />
                                <Route
                                    exact
                                    path={`${process.env.PUBLIC_URL}/auth/login`}
                                    component={Login}
                                />

                                <Route
                                    exact
                                    path={`${process.env.PUBLIC_URL}/noauth`}
                                    component={NoAuth}
                                />

                                <App>
                                    <Route
                                        path={`${process.env.PUBLIC_URL}/dashboard`}
                                        component={Dashboard}
                                    />

                                    <Route
                                        exact
                                        path={`${process.env.PUBLIC_URL}/stores`}
                                        component={StoresList}
                                    />
                                    <Route
                                        path={`${process.env.PUBLIC_URL}/stores/:id`}
                                        component={() => <ActivateStore/>}
                                    />
                                    <Route
                                        path={`${process.env.PUBLIC_URL}/ownstores`}
                                        component={OwnStoresList}
                                    />

                                    {/* Products */}
                                    <Route
                                        path={`${process.env.PUBLIC_URL}/allproducts`}
                                        component={Product_Table}
                                    />
                                    <Route
                                        path={`${process.env.PUBLIC_URL}/product-list`}
                                        component={Product_list}
                                    />
                                    <Route
                                        path={`${process.env.PUBLIC_URL}/product-detail/:id`}
                                        component={Product_detail}
                                    />

                                    <Route
                                        path={`${process.env.PUBLIC_URL}/create-product`}
                                        component={CreateProduct}
                                    />
                                    <Route
                                        path={`${process.env.PUBLIC_URL}/update-product/:id`}
                                        component={UpdateProduct}
                                    />

                                    {/* Users */}
                                    <Route
                                        path={`${process.env.PUBLIC_URL}/users-list`}
                                        component={Users_list}
                                    />

                                    {/* Settings */}
                                    <Route
                                        path={`${process.env.PUBLIC_URL}/main-category`}
                                        component={MainCategory}
                                    />
                                    <Route
                                        path={`${process.env.PUBLIC_URL}/category`}
                                        component={Category}
                                    />
                                    <Route
                                        path={`${process.env.PUBLIC_URL}/sub-category`}
                                        component={Sub_category}
                                    />
                                    <Route
                                        path={`${process.env.PUBLIC_URL}/filters`}
                                        component={Filters}
                                    />
                                    <Route
                                        path={`${process.env.PUBLIC_URL}/filter-options`}
                                        component={FilterOptions}
                                    />
                                    {/* Report */}
                                    <Route
                                        path={`${process.env.PUBLIC_URL}/porduct-report`}
                                        component={ProductReport}
                                    />
                                    <Route
                                        path={`${process.env.PUBLIC_URL}/store-report`}
                                        component={StoreReport}
                                    />
                                    <Route
                                        path={`${process.env.PUBLIC_URL}/product-comment-report`}
                                        component={productCommentReport}
                                    />
                                </App>
                            </Switch>
                        </ScrollContext>
                    </BrowserRouter>
                </IntlProvider>
            </Provider>
        );
    }
}

ReactDOM.render(<Root/>, document.getElementById("root"));
