const getAllStores = async (token = {}) => {
  const req = await fetch(`${process.env.REACT_APP_API_MEBZONE}/stores`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  try {
    const allStoreList = await req.json();
    return allStoreList;
  } catch (error) {
    console.log("error", error);
  }
};

const getOwnStore = async (token = {}, storeId) => {
  console.log(token)
  const req = await fetch(
    `${process.env.REACT_APP_API_MEBZONE}/stores/${storeId}`,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
  try {
    const store = await req.json();
    return store;
  } catch (error) {
    console.log("error", error);
  }
};

const putActivateStore = async (data = {}, token = {}, storeId) => {
  const req = await fetch(
    `${process.env.REACT_APP_API_MEBZONE}/stores/${storeId}`,
    {
      method: "PUT",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json; charset= UTF-8",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    }
  );
  try {
    const result = await req;
    return result;
  } catch (error) {
    console.log("error", error);
  }
};

export { getAllStores, getOwnStore, putActivateStore };
