const getDataTypes = async () => {
  const req = await fetch(`${process.env.REACT_APP_API_MEBZONE}/dataTypes`);
  try {
    const dataTypes = await req.json();
    return dataTypes;
  } catch (error) {
    console.log("error", error);
  }
};

const getAllFilters = async (token = {}, page = 1, pageSize = 10) => {
  const req = await fetch(
    `${process.env.REACT_APP_API_MEBZONE}/filters?page=${page}&size=${pageSize}`,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
  try {
    const filterList = await req;
    return filterList;
  } catch (error) {
    console.log("error", error);
  }
};

const filtersList = async (token = {}, dataTypeId) => {
  var baseUrl = "";
  if (dataTypeId == 2) {
    baseUrl = `${process.env.REACT_APP_API_MEBZONE}/filters?dataTypeId=2`;
  } else {
    baseUrl = `${process.env.REACT_APP_API_MEBZONE}/filters`;
  }

  const req = await fetch(baseUrl, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  try {
    const filterList = await req.json();
    return filterList;
  } catch (error) {
    console.log("error", error);
  }
};

const postFilter = async (token, data) => {
  // console.log(data);
  const req = await fetch(`${process.env.REACT_APP_API_MEBZONE}/filters`, {
    method: "POST",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json; charset= UTF-8",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(data),
  });
  try {
    const result = await req;
    return result;
  } catch (error) {
    console.log("error", error);
  }
};

const updateFilter = async (token, id, data) => {
  const req = await fetch(
    `${process.env.REACT_APP_API_MEBZONE}/filters/${id}`,
    {
      method: "POST",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json; charset= UTF-8",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    }
  );
  try {
    const result = await req;
    return result;
  } catch (error) {
    console.log("error", error);
  }
};

const getAllFilterOptions = async (token = {}, page = 1, pageSize = 10) => {
  const req = await fetch(
    `${process.env.REACT_APP_API_MEBZONE}/filteroptions?page=${page}&size=${pageSize}`,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
  try {
    const optionList = await req;
    return optionList;
  } catch (error) {
    console.log("error", error);
  }
};

const postFilterOption = async (token, data) => {
  const req = await fetch(
    `${process.env.REACT_APP_API_MEBZONE}/filteroptions`,
    {
      method: "POST",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json; charset= UTF-8",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    }
  );
  try {
    const result = await req;
    return result;
  } catch (error) {
    console.log("error", error);
  }
};

const updateFilterOption = async (token, id, data) => {
  const req = await fetch(
    `${process.env.REACT_APP_API_MEBZONE}/filteroptions/${id}`,
    {
      method: "POST",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json; charset= UTF-8",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    }
  );
  try {
    const result = await req;
    return result;
  } catch (error) {
    console.log("error", error);
  }
};

export {
  getDataTypes,
  getAllFilters,
  filtersList,
  getAllFilterOptions,
  postFilter,
  postFilterOption,
  updateFilter,
  updateFilterOption,
};
