import React, { Component, Fragment } from "react";
import { withTranslate } from "react-redux-multilingual";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Breadcrumb from "../common/breadcrumb";
import { getAllStores } from "../fetch/stores";
import "../settings/categories/still.scss";
import man from "../../assets/images/dashboard/man.png";

export class StoreList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: "",
      storeList: [],
    };
  }

  componentWillMount() {
    const token = localStorage.getItem("token");

    getAllStores(token).then((slist) => {
      // console.log(slist);
      this.setState({ storeList: [...slist], token: token });
    });
  }

  render() {
    const{translate, locale} = this.props
    return (
      <Fragment>
        <Breadcrumb title={translate("Store_list")} parent={translate("Stores")} />
        <div className="row products-admin ratio_asos">
          {this.state.storeList.map((store, i) => {
            return (
              <div className="col-xl-3 col-sm-6" key={i}>
                <div className="card">
                  <div className="products-admin">
                    <div className="card-body product-box">
                      <div className="img-wrapper">
                        <div className="front">
                          <a className="bg-size">
                            <img
                              className="img-fluid blur-up bg-img lazyloaded store-logo-img"
                              src={store.image ? store.image.imageUrl : man}
                            />
                          </a>
                        </div>
                      </div>
                      <div className="store-info">
                        <p>
                          {translate("Company_name")}: <span> {store.brandname}</span>{" "}
                        </p>
                        <p>
                          {translate("Address")}: <span> {store.address} </span>
                        </p>
                        <p>
                          {translate("Region")}: <span> {locale === "en" ? store.region.name_uz : store.region.name_ru} </span>
                        </p>
                        <p>
                          {translate("phone")}: <span> {store.phone} </span>{" "}
                        </p>
                        <Link
                          to={`${process.env.PUBLIC_URL}/stores/${store.id}`}
                        >
                          <button className="btn btn-primary see-more-btn">
                            {translate("In_detail")}
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  locale: state.Intl.locale,
});
export default connect(mapStateToProps)(withTranslate(StoreList));
