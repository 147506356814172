import React, { Component, Fragment } from "react";
import { withTranslate } from "react-redux-multilingual";
import { connect } from "react-redux";
import Breadcrumb from "../../common/breadcrumb";
import ReactTable from "react-table";
import "react-table/react-table.css";
import {
  getProductReport,
} from "../../fetch/categories";
import "../still.scss";
import {  archiveProductReport } from "../../fetch/products";

export class ProductReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: "",
      myData: [],
      pages: null,
      loading: true,
    };
    this.fetchData = this.fetchData.bind(this);
  }

  componentWillMount() {

    const token = localStorage.getItem("token");
    this.setState({ token: token });
  }

  fetchData(state, instance) {
    this.setState({ loading: true });
    getProductReport(this.state.token, state.page + 1, state.pageSize).then(
      async (res)=>{
        if (res.status == 200) {
          const totalLength = res.headers.get("totalLength");
          const pages = Math.ceil(totalLength / state.pageSize);
          this.setState({ pages });
          await res
            .clone()
            .json()
            .then((ulist) => {
              this.setState({
                myData: ulist,
                loading: false,
              });
            });
        }
      }
    )
  }

  archive = (e, id) => {
    e.preventDefault();
    const data = {
      isArchived: true
    }
  archiveProductReport(this.state.token, id, data).then((res)=>{
      console.log(res);
      if (res.status == 200) {
        window.location.reload()
      }
  })
  }
  unArchive = (e, id) => {
    e.preventDefault();
    const data = {
      isArchived: false
    }
    archiveProductReport(this.state.token, id, data).then((res)=>{
      if (res.status == 200) {
        window.location.reload()
      }
  })
  }

  render() {
    const {translate, locale} = this.props
    
    const columns = [
      {
        Header: translate("report_type"),
        accessor: locale === 'en' ? "reportType.name_uz" : "reportType.name_ru",
        style: {
          textAlign: "center",
        },
          filterable: false,
      },
      {
        Header: translate("product"),
        accessor: locale === "en" ? "product.name_uz" : "product.name_ru",
        style: {
          textAlign: "center",
        },
        sortable: false,
        filterable: false,
      },
      {
        Header: translate("Description"),
        accessor: locale === "en" ? "description" : "description",
        style: {
          textAlign: "center",
        },
        sortable: false,
        filterable: false,
      },
      {
        Header: "Archived",
        Cell: (row) => (
          <div>
            {!row.original.isArchived ? <span>
              not archived
            </span> : <span>
              archived
            </span>}
          </div>
        ),
        style: {
          textAlign: "center",
        },
        sortable: false,
        filterable: false,
      },
      {
        Header: <b>Action</b>,
        Cell: (row) => (
          <div>
            <span
                     onClick={(e) => {!row.original.isArchived ?
                      this.archive(e, row.original.id) : this.unArchive(e, row.original.id)
                    }}>
                <i
                  className="fa fa-file-archive-o"
                  style={{
                    width: 35,
                    fontSize: 20,
                    padding: 10,
                    color: `${!row.original.isArchived ? "rgb(40, 167, 69)" : "red"}`,
                  }}
                ></i>
              </span>
          </div>
        ),
        style: {
          textAlign: "center",
          cursor: "pointer",
        },
        sortable: false,
        filterable: false,
      },
    ];

    return (
      <Fragment>
        <Breadcrumb title={translate("Product_report")} parent={translate("Report_classifications")} />
        {/* <!-- Container-fluid starts--> */}
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  {/* <h5>{translate("Main_category_products")}</h5> */}
                </div>
                <div className="card-body">
              
                  <div className="clearfix"></div>
                  <div id="basicScenario" className="product-physical">
                    <ReactTable
                      manual
                      multiSelectOption={false}
                      defaultPageSize={10}
                      pages={this.state.pages}
                      data={this.state.myData}
                      columns={columns}
                      showPagination={true}
                      className="-striped -highlight"
                      onFetchData={this.fetchData}
                      filterable
                      previousText={translate("Previous")}
                      nextText={translate("Next")}
                      pageText={translate("Page")}
                      rowsText={translate("Rows")}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Container-fluid Ends--> */}
      </Fragment>
    );
  }
}


const mapStateToProps = (state) => ({
  locale: state.Intl.locale,
});

export default connect(mapStateToProps)(withTranslate(ProductReport));
