import React, { Component, Fragment } from "react";
import { withTranslate } from "react-redux-multilingual";
import { connect } from "react-redux";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import { Link, withRouter } from "react-router-dom";
import designer from "../../assets/images/dashboard/designer.jpg";
// import Tabset_profile from './tabset-profile';
import Breadcrumb from "../common/breadcrumb";
import { getOwnStore, putActivateStore } from "../fetch/stores";
import StoreProducts from "./storeProducts";
import ClipLoader from "react-spinners/ClipLoader";

export class ActivateStore extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: "",
      storeId: null,
      store: "",
      statusId: "",
      disableBtn: false
    };
  }

  componentWillMount() {
    const token = localStorage.getItem("token");
    const { id } = this.props.match.params;

    getOwnStore(token, id).then((store) => {
      this.setState({ token, storeId: id, store, statusId: store.statusId });
    });
  }

  // magazinni active qilish 
  activateStoreBtn = (e, value) => {
    e.preventDefault();
    this.setState({disableBtn: true})
    putActivateStore(
      { statusId: value },
      this.state.token,
      this.state.storeId
    ).then((res) => {
      if (res.status === 200) {
        setTimeout(() => {
          this.setState({ statusId: value, disableBtn: false });
        }, 2000);
      }
    });
  };

  render() {
    const { store } = this.state;
    const {translate} = this.props
    return (
      <Fragment>
        <div className="container-fluid pt-4">
          <Link to={`${process.env.PUBLIC_URL}/stores`}>
            <i className="fa fa-arrow-left" aria-hidden="true"></i> {translate("Back")}
          </Link>
        </div>
        <Breadcrumb title={store.brandname} parent={translate("Stores")} />
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-4">
              <div className="card">
                <div className="card-body">
                  <div className="profile-details text-center">
                    <img
                      src={store.image ? store.image.imageUrl : designer}
                      alt="shop logo"
                      style={{ height: "90px", objectFit: "cover" }}
                      className="img-fluid img-90 rounded-circle blur-up lazyloaded"
                    />
                    <h5 className="f-w-600 f-16 mb-0">{store.brandname}</h5>
                    <span>{store.phone}</span>
                    {/* <div className="social">
                      <div className="form-group btn-showcase">
                        <button className="btn social-btn btn-fb d-inline-block">
                          {" "}
                          <i className="fa fa-facebook"></i>
                        </button>
                        <button className="btn social-btn btn-twitter d-inline-block">
                          <i className="fa fa-google"></i>
                        </button>
                        <button className="btn social-btn btn-google d-inline-block mr-0">
                          <i className="fa fa-twitter"></i>
                        </button>
                      </div>
                    </div> */}
                  </div>
                  <hr />
                  <div className="project-status">
                     <h6 style={{ fontWeight: "600", color: "#000" }}>
                        {translate("Status")} :{" "}
                        <span
                          style={{ fontWeight: "400", fontStyle: "italic" }}
                        >
                         {this.state.statusId === 2 ? translate("Active") : translate("Disactive") }
                        </span>
                      </h6>
                    <div>
                        <button
                          className="btn btn-primary"
                          disabled={this.state.disableBtn}
                            style={{
                              cursor: this.state.disableBtn ? "not-allowed" : "",
                            }}
                          onClick={(e) => this.state.statusId === 2 ? this.activateStoreBtn(e, 1) : this.activateStoreBtn(e, 2)}
                        >
                         <span
                           style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                         }}
                         >
                           <ClipLoader
                             color={"#fff"}
                             loading={this.state.disableBtn}
                             size={20}
                            />
                           <span className="ml-1">
                              {this.state.statusId === 2 ?  translate("Disactivate") : translate("Activate") }
                           </span>
                         </span>
                        </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-8">
              <div className="card profile-card">
                <div className="card-body">
                  <Tabs>
                    <TabList className="nav nav-tabs tab-coupon">
                      <Tab className="nav-link">{translate("Products")}</Tab>
                      <Tab disabled className="nav-link">
                        {translate('Comments')}
                      </Tab>
                    </TabList>
                    <TabPanel>
                      <StoreProducts />
                    </TabPanel>
                    <TabPanel></TabPanel>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  locale: state.Intl.locale,
});
export default  connect(mapStateToProps) (withRouter(withTranslate( ActivateStore)));
