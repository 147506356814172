import React, { Component, Fragment } from "react";
import { withTranslate } from "react-redux-multilingual";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import ReactTable from "react-table";
import "react-table/react-table.css";
import moment from "moment";
import { getAllProducts } from "../fetch/products";

export class StoreProducts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: "",
      myData: [],
      pages: null,
      loading: true,
      storeId: "",
    };
    this.fetchData = this.fetchData.bind(this);
  }

  componentWillMount() {
    const token = localStorage.getItem("token");
    const { id } = this.props.match.params;
    this.setState({ token, storeId: id });
  }

  fetchData(state, instance) {
    this.setState({ loading: true });
    // console.log(state.pageSize, state.page, state.sorted, state.filtered);
    getAllProducts(
      this.state.token,
      state.page + 1,
      state.pageSize,
      this.state.storeId
    ).then(async (res) => {
      if (res.status == 200) {
        const totalLength = res.headers.get("totalLength");
        const pages = Math.ceil(totalLength / state.pageSize);
        this.setState({ pages });
        const list = await res
          .clone()
          .json()
          .then((plist) => {
            this.setState({
              myData: plist,
              loading: false,
            });
          });
      }
    });
  }

  render() {
    const {translate, locale} = this.props
    const columns = [
      {
        Header: "ID",
        accessor: "id",
        style: {
          textAlign: "center",
        },
      },
      {
        Header: translate("name"),
        accessor: locale === "en" ? "name_uz" : "name_ru",
        style: {
          textAlign: "center",
        },
      },
      {
        id: "storeId",
        Header: translate("Store"),
        accessor: (row) => {
          return row.store.brandname;
        },
        style: {
          textAlign: "center",
        },
      },
      {
        id: "mainCategory",
        Header: translate("Main_category"),
        accessor: (row) => {
          return locale ==="en" ? row.mainCategory.name_uz : row.mainCategory.name_ru;
        },
        style: {
          textAlign: "center",
        },
      },
      {
        id: "category",
        Header: translate("Category"),
        accessor: (row) => {
          return  locale === "en" ? row.category.name_uz : row.category.name_ru;
        },
        style: {
          textAlign: "center",
        },
      },
      {
        id: "subCategory",
        Header: translate("Subcategory"),
        accessor: (row) => {
          return locale === "en" ? row.subCategory.name_uz : row.subCategory.name_ru;
        },
        style: {
          textAlign: "center",
        },
      },
      {
        Header: translate("Rating"),
        accessor: "rate",
        style: {
          textAlign: "center",
        },
      },
      {
        id: "createdAt",
        Header: translate("Time_created"),
        accessor: (row) => {
          return moment(row.createdAt).format("DD/MM/YYYY");
        },
        style: {
          textAlign: "center",
        },
        filterable: false,
      },
      {
        Header: <b>Action</b>,
        Cell: (row) => (
          <div>
            <Link
              to={`${process.env.PUBLIC_URL}/product-detail/${row.original.id}`}
            >
              <span>
                <i
                  className="fa fa-search"
                  style={{
                    width: 35,
                    fontSize: 20,
                    padding: 11,
                    color: "rgb(40, 167, 69)",
                  }}
                ></i>
              </span>
            </Link>
          </div>
        ),
        style: {
          textAlign: "center",
          cursor: "pointer",
        },
        sortable: false,
        filterable: false,
      },
    ];
    return (
      <Fragment>
        <ReactTable
          manual
          multiSelectOption={false}
          pageSize={5}
          pages={this.state.pages}
          data={this.state.myData}
          columns={columns}
          showPagination={true}
          className="-striped -highlight"
          onFetchData={this.fetchData}
          filterable
          noDataText={translate("No_products_yet")}
          previousText={translate("Previous")}
          nextText={translate("Next")}
          pageText={translate("Page")}
          rowsText={translate("Rows")}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  locale: state.Intl.locale,
});

export default connect(mapStateToProps)(withRouter(withTranslate(StoreProducts)));
