async function readImageSize(file) {
  return new Promise((resolve, reject) => {
    let useBlob = false;
    const reader = new FileReader();

    reader.addEventListener("load", function () {
      try {
        const image = new Image();
        image.addEventListener("load", function () {
          const { width, height } = image;
          if (useBlob) {
            window.URL.revokeObjectURL(image.src);
          }

          resolve([width, height]);
        });

        image.src = useBlob ? window.URL.createObjectURL(file) : reader.result;
      } catch {
        reject();
      }
    });

    reader.readAsDataURL(file);
  });
}

function getFitSize(size, max, stretch = true) {
  if (!size) return { width: 0, height: 0 };

  if (!stretch) {
    if (size.width < max && size.height < max) {
      return size;
    }
  }

  if (size.width > size.height) {
    return { width: max, height: Math.floor((size.height * max) / size.width) };
  }

  return { width: Math.floor((size.width * max) / size.height), height: max };
}

export { getFitSize, readImageSize };
