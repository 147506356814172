import React, { Component, Fragment } from "react";
import { withTranslate } from "react-redux-multilingual";
import { connect } from "react-redux";
import Breadcrumb from "../common/breadcrumb";
import "react-toastify/dist/ReactToastify.css";
import ReactTable from "react-table";
import "react-table/react-table.css";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import { getUserList, putBlockUser } from "../fetch/users";

export class UsersList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: "",
      myData: [],
      pages: null,
      loading: true,
    };
    this.fetchData = this.fetchData.bind(this);
  }

  componentWillMount() {
    const token = localStorage.getItem("token");
    this.setState({ token: token });
  }

  fetchData(state, instance) {
    this.setState({ loading: true });
    // console.log(state.pageSize, state.page, state.sorted, state.filtered);
    getUserList(this.state.token, state.page + 1, state.pageSize).then(
      async (res) => {
        if (res.status == 200) {
          const totalLength = res.headers.get("totalLength");
          const pages = Math.ceil(totalLength / state.pageSize);
          this.setState({ pages });
          const list = await res
            .clone()
            .json()
            .then((ulist) => {
              this.setState({
                myData: ulist,
                loading: false,
              });
            });
        }
      }
    );
  }

  isBlockedUser(e, userId) {
    e.preventDefault();
    putBlockUser(this.state.token, userId).then((res) => {
      if (res.status === 200) {
        console.log("unBlocked");
        document
          .getElementById(userId + "isBlokedBtn")
          .classList.toggle("fa-lock");
        document
          .getElementById(userId + "isBlokedBtn")
          .classList.toggle("fa-unlock");
      } else {
        console.log("error with isBlockedUser");
      }
    });
  }

  render() {
    const {translate, locale} = this.props
    const columns = [
      {
        Header: "ID",
        accessor: "id",
        style: {
          textAlign: "center",
        },
      },
      {
        Header: translate("User_name"),
        accessor: "name",
        style: {
          textAlign: "center",
        },
      },
      {
        Header: translate("Surname"),
        accessor: "surname",
        style: {
          textAlign: "center",
        },
      },
      {
        Header: translate("email"),
        accessor: "email",
        style: {
          textAlign: "center",
        },
      },
      {
        id: "role",
        Header: translate("Role"),
        accessor: (row) => {
          return row.roles ? (locale === "en" ? row.roles[0].name_uz : row.roles[0].name_ru) : "";
        },
        style: {
          textAlign: "center",
        },
      },
      {
        Header: translate("phone"),
        accessor: "phone",
        style: {
          textAlign: "center",
        },
      },
      {
        Header: translate("Address"),
        accessor: "address",
        style: {
          textAlign: "center",
        },
      },
      {
        id: "gender",
        Header: translate("gender"),
        accessor: (row) => {
          return row.gender ?  (locale ==="en" ? row.gender.name_uz : row.gender.name_ru) : "";
        },
        style: {
          textAlign: "center",
        },
      },
      {
        id: "status",
        Header: translate("Status"),
        accessor: (row) => {
          return row.statusId === 2 ? translate("Active") : translate("Disactive");
        },
        style: {
          textAlign: "center",
        },
      },
      {
        id: "createdAt",
        Header: translate("Joined"),
        accessor: (row) => {
          return moment(row.createdAt).format("DD/MM/YYYY");
        },
        style: {
          textAlign: "center",
        },
        filterable: false,
      },
      {
        id: "lastLogin",
        Header: translate("Last_login"),
        accessor: (row) => {
          return moment(row.lastLogin).format("DD/MM/YYYY");
        },
        style: {
          textAlign: "center",
        },
        filterable: false,
      },
      {
        Header: <b>Action</b>,
        Cell: (row) => {
          return (
            <div>
              <span onClick={(e) => this.isBlockedUser(e, row.original.id)}>
                <i
                  id={row.original.id + "isBlokedBtn"}
                  className={
                    row.original.isBlocked ? "fa fa-lock" : "fa fa-unlock"
                  }
                  aria-hidden="true"
                  style={{
                    width: 35,
                    fontSize: 20,
                    padding: 10,
                    color: "rgb(40, 167, 69)",
                  }}
                ></i>
              </span>
            </div>
          );
        },
        style: {
          textAlign: "center",
          cursor: "pointer",
        },
        sortable: false,
        filterable: false,
      },
    ];

    return (
      <Fragment>
        <Fragment>
          <Breadcrumb title={translate("List_of_users")} parent={translate("users")} />
          <div className="container-fluid">
            <div className="card">
              <div className="card-header">
                <h5>{translate("Info_about_users")}</h5>
              </div>
              <div className="card-body">
                <div className="clearfix"></div>
                <div
                  id="batchDelete"
                  className="category-table user-list order-table coupon-list-delete"
                >
                  <ReactTable
                    manual
                    multiSelectionOption={false}
                    defaultPageSize={10}
                    pages={this.state.pages}
                    data={this.state.myData}
                    columns={columns}
                    showPagination={true}
                    className="-striped -highlight"
                    onFetchData={this.fetchData}
                    filterable
                    previousText={translate("Previous")}
                    nextText={translate("Next")}
                    pageText={translate("Page")}
                    rowsText={translate("Rows")}
                  />
                  <ToastContainer />
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      </Fragment>
    );
  }
}


const mapStateToProps = (state, ownProps) => ({
  locale: state.Intl.locale,
});

export default connect(mapStateToProps)(withTranslate(UsersList));
