import React, { Component, Fragment } from "react";
import { Login as LoginApi, checkToken } from "./auth";

export class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tryAgain: false,
    };
  }
  componentWillMount() {

    const tempToken = new URLSearchParams(this.props.location.search).get(
      "token"
    );
    const storeId = new URLSearchParams(this.props.location.search).get(
      "storeId"
    );

    if (tempToken) {
      console.log("newToken")
      localStorage.setItem("storeId", storeId);
      LoginApi(tempToken).then(async (res) => {
        if (res.status === 401) {
          this.props.history.push("/noauth");
        }
        if (res.status === 429) {
          this.setState({ tryAgain: true });
          return;
        }
        if (res.status === 200) {
          const resToken = await res
            .clone()
            .json()
            .then((json) => {
              localStorage.setItem("token", json.token);
              localStorage.setItem("role", json.roles[0].id);
              if (json.roles[0].id === 1) {
                this.props.history.push("/dashboard");
              } else {
                this.props.history.push("/product-list");
              }
            });
        }
      });
    }

    else {
      console.log("oldToken")
      const token = localStorage.getItem("token")
      const role = localStorage.getItem("role")
      const storeIdLocal = localStorage.getItem("storeId")

      if (token && role) {
        if (storeIdLocal && role === "2") {
          checkToken(token).then(res => {
            if (!res.isExpired) {
              this.props.history.push("/product-list");
            }
            else {
              this.props.history.push("/noauth");
            }
          })
        }
        else if (role === "1") {
          checkToken(token).then(res => {
            if (!res.isExpired) {
              this.props.history.push("/dashboard");
            }
          })
        }
        else {
          this.props.history.push("/noauth");
        }
      }
      else {
        this.props.history.push("/noauth");
      }
    }
  }

  render() {
    return (
      <Fragment>
        <div>
          {this.state.tryAgain && (
            <p>Iltimos biroz vaqtdan keyin login qiling</p>
          )}
        </div>
      </Fragment>
    );
  }
}

export default Login;
