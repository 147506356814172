const Login = async (temporaryToken = {}) => {
  const tempToken = {
    tempToken: temporaryToken,
  };

  const req = await fetch(
    `${process.env.REACT_APP_API_MEBZONE}/auth/loginBack`,
    {
      method: "POST",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json; charset= UTF-8",
      },
      body: JSON.stringify(tempToken),
    }
  );
  try {
    const r = await req;
    return r;
  } catch (error) {
    console.log("error", error);
  }
};

const checkToken = async (token) => {
  const req = await fetch(
    `${process.env.REACT_APP_API_MEBZONE}/auth/tokenExpire`,
    {
      method: "GET",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
  try {
    const result = await req.json();
    return result;
  } catch (err) {
    console.log("err", err);
  }
};


export { Login, checkToken };
