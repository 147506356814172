const getAllProducts = async (token = {}, page = 1, pageSize = 10, storeId) => {
  var baseUrl;
  if (storeId) {
    baseUrl = `${process.env.REACT_APP_API_MEBZONE}/products?storeId=${storeId}?page=${page}&size=${pageSize}`;
  } else {
    baseUrl = `${process.env.REACT_APP_API_MEBZONE}/products?page=${page}&size=${pageSize}`;
  }
  const req = await fetch(baseUrl, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  try {
    const allProductList = await req;
    return allProductList;
  } catch (error) {
    console.log("error", error);
  }
};

const archiveMaincategories = async (token = {}, productId, data) => {
  // return token, productId;
  // console.log( token, productId)
  const req = await fetch(`${process.env.REACT_APP_API_MEBZONE}/maincategories/${productId}/archive`, {
    method: 'PUT',
    headers: {
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(data),
  });
  try {
    const allStoreList = await req;
    return allStoreList;
  } catch (error) {
    console.log("error", error);
  }
};

const archiveProductReport = async (token = {}, productId, data) => {
  // return token, productId;
  // console.log( token, productId)
  const req = await fetch(`${process.env.REACT_APP_API_MEBZONE}/productReports/${productId}/archive`, {
    method: 'PUT',
    headers: {
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(data),
  });
  try {
    const allStoreList = await req;
    return allStoreList;
  } catch (error) {
    console.log("error", error);
  }
};

const archiveStoreReport = async (token = {}, productId, data) => {
  // return token, productId;
  // console.log( token, productId)
  const req = await fetch(`${process.env.REACT_APP_API_MEBZONE}/storeReports/${productId}/archive`, {
    method: 'PUT',
    headers: {
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(data),
  });
  try {
    const allStoreList = await req;
    return allStoreList;
  } catch (error) {
    console.log("error", error);
  }
};

const archiveProductCommentReport = async (token = {}, productId, data) => {
  // return token, productId;
  // console.log( token, productId)
  const req = await fetch(`${process.env.REACT_APP_API_MEBZONE}/productCommentReports/${productId}/archive`, {
    method: 'PUT',
    headers: {
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(data),
  });
  try {
    const allStoreList = await req;
    return allStoreList;
  } catch (error) {
    console.log("error", error);
  }
};

const archiveCategories = async (token = {}, productId, data) => {
  // return token, productId;
  // console.log( token, productId)
  const req = await fetch(`${process.env.REACT_APP_API_MEBZONE}/categories/${productId}/archive`, {
    method: 'PUT',
    headers: {
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(data),
  });
  try {
    const allStoreList = await req;
    return allStoreList;
  } catch (error) {
    console.log("error", error);
  }
};
const archiveSubcategories = async (token = {}, productId, data) => {
  // return token, productId;
  // console.log( token, productId)
  const req = await fetch(`${process.env.REACT_APP_API_MEBZONE}/subcategories/${productId}/archive`, {
    method: 'PUT',
    headers: {
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(data),
  });
  try {
    const allStoreList = await req;
    return allStoreList;
  } catch (error) {
    console.log("error", error);
  }
};


export { getAllProducts, archiveMaincategories, archiveCategories,archiveSubcategories,archiveProductReport,archiveStoreReport,archiveProductCommentReport };
