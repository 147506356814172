import * as types from "../constants/ActionTypes";

const dataReducerDefaultState = {
  stores: "",
};

const dataReducer = (state = dataReducerDefaultState, action) => {
  switch (action.type) {
    case types.DATA:
      return {
        ...state,
        stores: action.data,
      };
    default:
      return state;
  }
};

export default dataReducer;
