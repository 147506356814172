import React, { Component } from "react";
import { withTranslate } from "react-redux-multilingual";
import { Home } from "react-feather";
import { Link } from "react-router-dom";

export class Breadcrumb extends Component {
  render() {
    const role = localStorage.getItem("role");
     const {translate} = this.props
    return (
      <div className="container-fluid">
        <div className="page-header">
          <div className="row">
            <div className="col-lg-6">
              <div className="page-header-left">
                <h3>
                  {this.props.title}
                  {role == 1 ? (
                     <small style={{ textTransform: "none" }}>{translate("Mebzone_admin_panel")}</small>
                  ) : (
                    <small style={{ textTransform: "none" }} >
                      {translate("Mebzone_seller_panel")}
                    </small>
                  )}
                </h3>
              </div>
            </div>
            <div className="col-lg-6">
              <ol className="breadcrumb pull-right">
                <li className="breadcrumb-item">
                  <Link
                    to={
                      role == 1
                        ? `${process.env.PUBLIC_URL}/dashboard`
                        : `${process.env.PUBLIC_URL}/product-list`
                    }
                  >
                    <Home />
                  </Link>
                </li>
                <li className="breadcrumb-item">{this.props.parent}</li>
                <li className="breadcrumb-item active">{this.props.title}</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    );
  }
}


export default withTranslate(Breadcrumb);
