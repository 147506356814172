import React, { Component, Fragment } from "react";
import { withTranslate } from "react-redux-multilingual";
import Breadcrumb from "../../common/breadcrumb";
import Tabset_product from "./tabset-product";

export class Create_product extends Component {
  render() {
    const { translate } = this.props
    return (
      <Fragment>
        <Breadcrumb title={translate("add_product")} parent={translate("Products")} />
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <h5>{translate("add_product")}</h5>
                </div>
                <div className="card-body">
                  <Tabset_product />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default withTranslate(Create_product);
