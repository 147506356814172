import React, { Component, Fragment } from "react";
import { Link, withRouter } from "react-router-dom";
import { withTranslate } from "react-redux-multilingual";
import { connect } from "react-redux";
import Breadcrumb from "../../common/breadcrumb";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import StarRatingComponent from "react-star-rating-component";
import ClipLoader from "react-spinners/ClipLoader";

// image import
import one from "../../../assets/images/pro3/1.jpg";
import { getProduct, putActivateProduct, putBlockProduct } from "./api";

export class Product_detail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: "",
      productId: null,
      productData: "",
      productStatus: null,
      productIsBlocked: null,
      role: null,
      rating: 1,
      nav1: null,
      nav2: null,
      disableActivateBtn: false,
      disableBlockBtn: false
    };
  }

  componentWillMount() {
    const token = localStorage.getItem("token");
    const role = localStorage.getItem("role");
    const { id } = this.props.match.params;
    getProduct(token, id).then((product) => {
      // console.log(product);
      this.setState({
        productData: product,
        productStatus: product.statusId,
        productIsBlocked: product.isBlocked,
        productId: id,
        role,
        token,
      });
    });
  }

  // Productni publish qilish
  activateProductBtn = (e, value) => {
    e.preventDefault();
    this.setState({disableActivateBtn: true})
    putActivateProduct(
      { statusId: value },
      this.state.token,
      this.state.productId
    ).then((res) => {
      if (res.status === 200) {
        setTimeout(() => {
          this.setState({ productStatus: value, disableActivateBtn: false });
        }, 2000);
      }
    });
  };

// Productni block qilish
  blockProductBtn = (e, value) => {
    e.preventDefault();
    this.setState({disableBlockBtn: true})
    putBlockProduct(
      { isBlocked: value },
      this.state.token,
      this.state.productId
    ).then((res) => {
      if (res.status === 200) {
        setTimeout(() => {
          this.setState({ productIsBlocked: value, disableBlockBtn: false });
        }, 2000);
      }
    });
  };

  onStarClick(nextValue, prevValue, name) {
    this.setState({ rating: nextValue });
  }
  onStarHover(nextValue, prevValue, name) {
    this.setState({ rating: nextValue });
  } /* on icon hover handler */
  onStarHoverOut(nextValue, prevValue, name) {
    this.setState({ rating: nextValue });
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,
    });
  }
  render() {
    const { rating, productData, role } = this.state;
    const { images } = productData;
    const {translate, locale, history} = this.props
    console.log(history, "hh")
    return (
      <Fragment>
        <div className="container-fluid pt-4">
            <button style={{border: "none", background: "none", outline: "none", color: "#00a075"}} onClick={()=> history.goBack()}>       
              <i className="fa fa-arrow-left" aria-hidden="true"></i> {translate("Back")}
            </button>
        </div>
        <Breadcrumb title={translate("Info_about_product")} parent={translate("Products")} />
        <div className="container-fluid">
          <div className="card">
            <div className="row product-page-main card-body">
              <div className="col-xl-4">
                <Slider
                  asNavFor={this.state.nav2}
                  ref={(slider) => (this.slider1 = slider)}
                  className="product-slider"
                >
                  {images ? (
                    images.map((image) => {
                      return (
                        <div className="item" key={image.id}>
                          <img
                            className="img-fluid"
                            src={image.imageUrl}
                            alt="product-pic"
                          />
                        </div>
                      );
                    })
                  ) : (
                    <div className="item">
                      <img className="img-fluid" src={one} alt="product-pic" />
                    </div>
                  )}
                </Slider>

                <Slider
                  asNavFor={this.state.nav1}
                  ref={(slider) => (this.slider2 = slider)}
                  slidesToShow={
                    images ? (images.length > 3 ? 4 : images.length) : 1
                  }
                  swipeToSlide={true}
                  focusOnSelect={true}
                  className="small-slick"
                >
                  {images ? (
                    images.map((image) => {
                      return (
                        <div
                          key={image.id}
                          className={images.length === 1 ? "w-25" : "item"}
                        >
                          <img
                            className="img-fluid"
                            src={image.imageUrl}
                            alt="product-pic"
                          />
                        </div>
                      );
                    })
                  ) : (
                    <div className="item">
                      <img className="img-fluid" src={one} alt="product-pic" />
                    </div>
                  )}
                </Slider>
              </div>
              <div className="col-xl-8">
                <Tabs>
                  <TabList className="nav nav-tabs tab-coupon">
                    <Tab className="nav-link">{translate("Info_about_product")}</Tab>
                    <Tab disabled className="nav-link">
                      {translate("Comments")}
                    </Tab>
                  </TabList>
                  <TabPanel>
                    <div className="product-page-details product-right mb-0">
                      <h2>{locale === "en" ? productData.name_uz : productData.name_ru}</h2>
                      <div style={{ fontSize: 27, height: 31 }}>
                        <StarRatingComponent
                          name="rate1"
                          starCount={5}
                          value={Number(productData.rate)}
                          //value={rating}
                          //onStarClick={this.onStarClick.bind(this)}
                          //onStarHover={this.onStarHover.bind(this)}
                          //onStarHoverOut={this.onStarHoverOut.bind(this)}
                        />
                      </div>
                      <hr />
                      <div className="product-price digits mt-2">
                        <h3>{productData.price} UZS</h3>
                      </div>
                      <h6 className="product-title">
                        {translate("Product_description")}:
                      </h6>
                      <p>{locale === "en" ? productData.description_uz : productData.description_ru}</p>
                      {productData.store ? (
                        <h6 className="product-title">
                          {translate("Manufacturer")} :{" "}
                          <span
                            style={{ fontWeight: "400", fontStyle: "italic" }}
                          >
                            {productData.store.brandname}
                          </span>
                        </h6>
                      ) : (
                        ""
                      )}
                      <hr />
                      {productData.mainCategory ? (
                        <h6 className="product-title">
                          {translate("Main_category")} :{" "}
                          <span
                            style={{ fontWeight: "400", fontStyle: "italic" }}
                          >
                            {locale === 'en' ? productData.mainCategory.name_uz : productData.mainCategory.name_ru}
                          </span>
                        </h6>
                      ) : (
                        ""
                      )}
                      {productData.category ? (
                        <h6 className="product-title">
                          {translate("Category")} :{" "}
                          <span
                            style={{ fontWeight: "400", fontStyle: "italic" }}
                          >
                            {locale === "en" ? productData.category.name_uz : productData.category.name_ru}
                          </span>
                        </h6>
                      ) : (
                        ""
                      )}
                      {productData.subCategory ? (
                        <h6 className="product-title">
                          {translate("Subcategory")} :{" "}
                          <span
                            style={{ fontWeight: "400", fontStyle: "italic" }}
                          >
                            {locale === "en" ? productData.subCategory.name_uz : productData.subCategory.name_ru}
                          </span>
                        </h6>
                      ) : (
                        ""
                      )}
                      
                        <h6 className="product-title">
                          {translate("isAvailable")} :{" "}
                          <span
                            style={{ fontWeight: "400", fontStyle: "italic" }}
                          >
                            {productData.isAvailable ? translate("Yes") : translate("No")} 
                          </span>
                        </h6>
                      
                        <h6 className="product-title">
                          {translate("Delivery")} :{" "}
                          <span
                            style={{ fontWeight: "400", fontStyle: "italic" }}
                          >
                            {productData.isDeliverable ?  translate("Yes") : translate("No") }
                          </span>
                        </h6>
                      
                      <hr />
                      {this.state.productStatus === 1 ? (
                        <h6 className="product-title">
                          {translate("Status")} :{" "}
                          <span
                            style={{ fontWeight: "400", fontStyle: "italic" }}
                          >
                            {translate("Disactive")}
                          </span>
                        </h6>
                      ) : (
                        <h6 className="product-title">
                          {translate("Status")} :{" "}
                          <span
                            style={{ fontWeight: "400", fontStyle: "italic" }}
                          >
                            {translate("Active")}
                          </span>
                        </h6>
                      )}
                      {this.state.productIsBlocked ? (
                        <p style={{ fontWeight: "400", fontStyle: "italic", color: "red" }}>
                          {translate("Blocked")}
                        </p>
                      ) : (
                        ""
                      )}
                     {
                       this.state.role == 2 && (
                         <button 
                          className="btn btn-primary m-r-10"
                          type="button"
                          disabled={this.state.disableActivateBtn}
                          style={{
                               cursor: this.state.disableActivateBtn ? "not-allowed" : "",
                          }}
                          onClick ={(e)=> this.state.productStatus == 1 ? this.activateProductBtn(e, 2) : this.activateProductBtn(e, 1)}
                         >
                           <span 
                             style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                           }}
                           >
                            <ClipLoader
                             color={"#fff"}
                             loading={this.state.disableActivateBtn}
                             size={20}
                            />
                             <span className="ml-1">
                               {this.state.productStatus == 1 ? translate("To_publish") : translate("Cancel_publish")}
                             </span>
                           </span>
                         </button>
                       )
                     }
                      {
                        this.state.role == 1 && (
                          <button
                            className="btn btn-primary m-r-10 mt-2"
                            type="button"
                            disabled={this.state.disableBlockBtn}
                            style={{
                              cursor: this.state.disableBlockBtn ? "not-allowed" : "",
                            }}
                             onClick ={e => {this.state.productIsBlocked ?  this.blockProductBtn(e, 0) : this.blockProductBtn(e, 1)}}
                          >
                          <span
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                           }}
                          >
                           <ClipLoader
                             color={"#fff"}
                             loading={this.state.disableBlockBtn}
                             size={20}
                            />
                            <span className="ml-1">
                              { this.state.productIsBlocked ?  translate("Cancel_block") : translate("To_block") }
                            </span>
                          </span>
                          </button>
                        )
                      }
                    </div>
                  </TabPanel>
                  <TabPanel></TabPanel>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}


const mapStateToProps = (state, ownProps) => ({
  locale: state.Intl.locale,
});

export default  connect(mapStateToProps)(withRouter(withTranslate(Product_detail))) ;
