import React, { Component, Fragment } from "react";
import { withTranslate } from "react-redux-multilingual";
import { connect } from "react-redux";
import SimpleReactValidator from "simple-react-validator";
import Select from "react-select";
import Breadcrumb from "../../common/breadcrumb";
import moment from "moment";
import Modal from "react-responsive-modal";
import "react-toastify/dist/ReactToastify.css";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { ToastContainer, toast } from "react-toastify";
import ClipLoader from "react-spinners/ClipLoader";

import {
  getAllFilterOptions,
  filtersList,
  postFilterOption,
  updateFilterOption,
} from "../../fetch/filters";

import "../categories/still.scss";

export class FilterOptions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: "",
      myData: [],
      pages: null,
      loading: true,
      open: false,
      action: null,
      value_uz: "",
      value_ru: "",
      filterOptions: [],
      selectedFilter: null,
      filterId: null,
      id: null,
      index: null,
      times: [],
      disableBtn1: false,
      disableBtn2: false,

    };
    this.fetchData = this.fetchData.bind(this);
    this.handleInputs = this.handleInputs.bind(this);
  }

  componentWillMount() {
    this.validator = new SimpleReactValidator({
      messages: {
        required: this.props.translate("Fill_the_gap"),
      },
    });
    const token = localStorage.getItem("token");
    filtersList(token, 2).then((flist) => {
      // console.log(flist);
      let newArray = [];
      flist.map((f) => {
        newArray.push({ value: f.id, label: this.props.locale === "en" ? f.name_uz : f.name_ru });
      });
      this.setState({ filterOptions: newArray, token: token });
    });
  }

  fetchData(state, instance) {
    this.setState({ loading: true });
    // console.log(state.pageSize, state.page, state.sorted, state.filtered);
    getAllFilterOptions(this.state.token, state.page + 1, state.pageSize).then(
      async (res) => {
        if (res.status == 200) {
          const totalLength = res.headers.get("totalLength");
          const pages = Math.ceil(totalLength / state.pageSize);
          this.setState({ pages });
          const list = await res
            .clone()
            .json()
            .then((ulist) => {
              this.setState({
                myData: ulist,
                loading: false,
              });
            });
        }
      }
    );
  }

  onOpenModal = (type) => {
    this.setState({ open: true, action: type });
  };
  onCloseModal = () => {
    this.setState({
      open: false,
      action: null,
      value_uz: "",
      value_ru: "",
      id: null,
      index: null,
      selectedFilter: null,
      filterId: null,
      times: [],
    });
  };

  handleInputs(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  handleSingleSelect = (selectedFilter) => {
    this.setState({ selectedFilter });
    if (selectedFilter) {
      const { times } = this.state;
      if (times.length > 0) {
        times.map((el) => {
          el.filterId = selectedFilter.value;
        });
      }
      this.setState({
        filterId: selectedFilter.value,
        times,
      });
    }
  };

  addFilterOptionBtn = (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      this.setState({disableBtn1: true})
      const { token, value_uz, value_ru, filterId, times } = this.state;
      const finalArray = [{ value_uz, value_ru, filterId }, ...times].filter(
        (el) => el.value_uz !== "" && el.value_ru !== ""
      );
      //  console.log(finalArray);

      postFilterOption(token, finalArray).then(async (res) => {
        if (res.status === 200) {
          const r = await res.clone().json();
          this.setState({
            myData: [...r, ...this.state.myData],
          });
          setTimeout(() => {
            this.setState({ disableBtn1: false });
            this.onCloseModal();
            toast.success(this.props.translate("Successfully_added"));
          }, 2000);

        } else {
          console.log("wrong status with add filter option post");
        }
      });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  updateFilterOptionBtn = (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      this.setState({disableBtn2: true})
      const { token, value_uz, value_ru, filterId, id } = this.state;
      updateFilterOption(token, id, { value_uz, value_ru, filterId }).then(
        async (res) => {
          if (res.status === 200) {
            const r = await res.clone().json();
            const newData = this.state.myData.filter((d) => d.id != id);
            const sortedData = [r, ...newData].sort(function (a, b) {
              return a.id - b.id;
            });
            this.setState({
              myData: sortedData,
            });
            setTimeout(() => {
              this.setState({ disableBtn2: false });
              this.onCloseModal();
              toast.success(this.props.translate("Successfully_updated"));
            }, 2000);
          } else {
            console.log("wrong status with update filter option post");
          }
        }
      );
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  handleLoopInputsUz = (e, index) => {
    e.preventDefault();
    const { times } = this.state;
    times[index].value_uz = e.target.value;
    this.setState({ times });
  };

  handleLoopInputsRu = (e, index) => {
    e.preventDefault();
    const { times } = this.state;
    times[index].value_ru = e.target.value;
    this.setState({ times });
  };

  removeItem = (e, index) => {
    e.preventDefault();
    const { times } = this.state;
    times.splice(index, 1);
    this.setState({
      times,
    });
  };

  render() {
    const {translate, locale} = this.props
    const { open } = this.state;
    const columns = [
      {
        Header: "ID",
        accessor: "id",
        style: {
          textAlign: "center",
        },
      },
      {
        id: "value",
        Header: translate("Option"),
        accessor: (row) => {
          return (locale === "en" ? row.stringValue_uz : row.stringValue_ru) || row.intValue || row.booleanValue;
        },
        style: {
          textAlign: "center",
        },
      },

      {
        id: "filter",
        Header: translate("Filter"),
        accessor: (f) => locale === "en" ? f.filter.name_uz : f.filter.name_ru,
        style: {
          textAlign: "center",
        },
      },
      {
        id: "createdAt",
        Header: translate("Time_created"),
        accessor: (row) => {
          return moment(row.createdAt).format("DD/MM/YYYY");
        },
        style: {
          textAlign: "center",
        },
        filterable: false,
      },
      {
        id: "updatedAt",
        Header: translate("Time_updated"),
        accessor: (row) => {
          return moment(row.updateAt).format("DD/MM/YYYY");
        },
        style: {
          textAlign: "center",
        },
        filterable: false,
      },
      {
        Header: <b>Action</b>,
        Cell: (row) => (
          <div>
            <span
              onClick={() => {
                //console.log(row);
                const chosen = this.state.filterOptions.find(
                  (x) => x.value === row.original.filterId
                );
                const index = this.state.filterOptions.indexOf(chosen);
                this.setState({
                  id: row.original.id,
                  value_uz: row.original.stringValue_uz,
                  value_ru: row.original.stringValue_ru,
                  index: index,
                  selectedFilter: chosen,
                  filterId: chosen.value,
                });
                this.onOpenModal("edit");
              }}
            >
              <i
                className="fa fa-pencil"
                style={{
                  width: 35,
                  fontSize: 20,
                  padding: 11,
                  color: "rgb(40, 167, 69)",
                }}
              ></i>
            </span>
          </div>
        ),
        style: {
          textAlign: "center",
          cursor: "pointer",
        },
        sortable: false,
        filterable: false,
      },
    ];

    return (
      <Fragment>
        <Breadcrumb title={translate("Filter_parameters")} parent={translate("Product_classifications")} />

        {/* <!-- Container-fluid starts--> */}
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <h5>{translate("Filter_parameters")}</h5>
                </div>
                <div className="card-body">
                  <div className="btn-popup pull-right">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => this.onOpenModal("add")}
                      data-toggle="modal"
                      data-original-title="test"
                      data-target="#exampleModal"
                    >
                      {translate('Add_filter_parameter')}
                    </button>
                    <Modal
                      open={open}
                      onClose={this.onCloseModal}
                      closeOnOverlayClick={false}
                    >
                      <div className="modal-header">
                        <h5
                          className="modal-title f-w-600"
                          id="exampleModalLabel2"
                        >
                          {this.state.action == "add"
                            ? translate('Add_filter_parameter')
                            : translate("Update_filter_parameter")}
                        </h5>
                      </div>
                      <div className="modal-body">
                        <form>
                          <div className="form-group">
                            <label className="col-form-label">
                              {translate("Select_filters")}
                            </label>

                            <Select
                              defaultValue={
                                this.state.action == "edit"
                                  ? this.state.filterOptions[this.state.index]
                                  : ""
                              }
                              className="basic-single"
                              classNamePrefix="select"
                              name="color"
                              options={this.state.filterOptions}
                              placeholder={translate("Filters")}
                              onChange={this.handleSingleSelect}
                              isClearable="false"
                              isSearchable="true"
                              onBlur={() =>
                                this.validator.showMessageFor("selectedFilter")
                              }
                            />
                            {this.validator.message(
                              "selectedFilter",
                              this.state.selectedFilter,
                              "required"
                            )}

                            <label className="col-form-label">
                              {translate("Filter_parameter_name")}:
                            </label>
                            <div className="form-row">
                              <div className="col">
                                <input
                                  type="text"
                                  name="value_uz"
                                  value={this.state.value_uz}
                                  placeholder={
                                    this.state.action == "edit"
                                      ? this.state.value_uz
                                      : translate("In_uzbek")
                                  }
                                  onChange={this.handleInputs}
                                  className="form-control"
                                  onBlur={() =>
                                    this.validator.showMessageFor("value_uz")
                                  }
                                />
                                {this.validator.message(
                                  "value_uz",
                                  this.state.value_uz,
                                  "required"
                                )}
                              </div>
                              <div className="col">
                                <input
                                  type="text"
                                  name="value_ru"
                                  value={this.state.value_ru}
                                  placeholder={
                                    this.state.action == "edit"
                                      ? this.state.value_ru
                                      : translate("In_russian")
                                  }
                                  onChange={this.handleInputs}
                                  className="form-control"
                                  onBlur={() =>
                                    this.validator.showMessageFor("value_ru")
                                  }
                                />
                                {this.validator.message(
                                  "value_ru",
                                  this.state.value_ru,
                                  "required"
                                )}
                              </div>
                              {this.state.action == "add" && (
                                <div className="col-2">
                                  {this.state.times.length < 4 ? (
                                    <button
                                      onClick={() => {
                                        this.setState({
                                          times: [
                                            ...this.state.times,
                                            {
                                              value_uz: "",
                                              value_ru: "",
                                              filterId: this.state.filterId,
                                            },
                                          ],
                                        });
                                      }}
                                      type="button"
                                      className="btn btn-primary"
                                    >
                                      <i
                                        className="fa fa-plus"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              )}
                            </div>

                            {this.state.times.map((el, index) => {
                              return (
                                <div key={index}>
                                  <label className="col-form-label">
                                  {translate("Filter_parameter_name")}:
                                  </label>
                                  <div className="form-row">
                                    <div className="col">
                                      <input
                                        type="text"
                                        placeholder={translate("In_uzbek")}
                                        className="form-control"
                                        value={el.value_uz}
                                        onChange={(e) => {
                                          this.handleLoopInputsUz(e, index);
                                        }}
                                        onBlur={() =>
                                          this.validator.showMessageFor(
                                            "inputOpUz" + index
                                          )
                                        }
                                      />
                                      {this.validator.message(
                                        "inputOpUz" + index,
                                        this.state.times[index].value_uz,
                                        "required"
                                      )}
                                    </div>
                                    <div className="col">
                                      <input
                                        type="text"
                                        placeholder={translate("In_russian")}
                                        className="form-control"
                                        value={el.value_ru}
                                        onChange={(e) => {
                                          this.handleLoopInputsRu(e, index);
                                        }}
                                        onBlur={() =>
                                          this.validator.showMessageFor(
                                            "inputOpRu" + index
                                          )
                                        }
                                      />
                                      {this.validator.message(
                                        "inputOp" + index,
                                        this.state.times[index].value_ru,
                                        "required"
                                      )}
                                    </div>
                                    <div className="col-2">
                                      <button
                                        onClick={(e) => {
                                          this.removeItem(e, index);
                                        }}
                                        type="button"
                                        className="btn btn-warning text-center"
                                      >
                                        <i
                                          className="fa fa-minus"
                                          aria-hidden="true"
                                        ></i>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </form>
                      </div>
                      <div className="modal-footer">
                        {this.state.action == "add" ? (
                          <button
                            type="button"
                            disabled={this.state.disableBtn1}
                            style={{
                            cursor: this.state.disableBtn1 ? "not-allowed" : "",
                            }}
                            className="btn btn-primary"
                            onClick={(event) => this.addFilterOptionBtn(event)}
                          >
                    
                           <span
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                               }}
                           >
                            <ClipLoader
                             color={"#fff"}
                             loading={this.state.disableBtn1}
                             size={20}
                            />
                              <span className="ml-1">
                               {translate("Add")}
                             </span>
                           </span>
                          </button>
                        ) : (
                          <button
                            type="button"
                            disabled={this.state.disableBtn2}
                            style={{
                            cursor: this.state.disableBtn2 ? "not-allowed" : "",
                            }}
                            className="btn btn-primary"
                            onClick={(event) =>
                              this.updateFilterOptionBtn(event)
                            }
                          >
                            <span
                               style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                               }}
                             >
                            <ClipLoader
                             color={"#fff"}
                             loading={this.state.disableBtn2}
                             size={20}
                            />
                              <span className="ml-1">
                                {translate("Update")}
                              </span>
                            </span>
                          </button>
                        )}
                        <button
                          type="button"
                          className="btn btn-secondary"
                          onClick={() => this.onCloseModal("VaryingMdo")}
                        >
                            {translate("Cancel")}
                        </button>
                      </div>
                    </Modal>
                  </div>
                  <div className="clearfix"></div>
                  <div id="basicScenario" className="product-physical">
                    <ReactTable
                      manual
                      multiSelectOption={false}
                      defaultPageSize={10}
                      pages={this.state.pages}
                      data={this.state.myData}
                      columns={columns}
                      showPagination={true}
                      className="-striped -highlight"
                      onFetchData={this.fetchData}
                      filterable
                      previousText={translate("Previous")}
                      nextText={translate("Next")}
                      pageText={translate("Page")}
                      rowsText={translate("Rows")}
                    />
                    <ToastContainer />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Container-fluid Ends--> */}
      </Fragment>
    );
  }
}


const mapStateToProps = (state) => ({
  locale: state.Intl.locale,
});

export default connect(mapStateToProps)(withTranslate(FilterOptions));
