const postImage = async (data = {}, token) => {
  var formData = new FormData();
  formData.append("file", data);
  const req = await fetch(`${process.env.REACT_APP_API_MEBZONE}/files`, {
    method: "POST",
    credentials: "same-origin",
    headers: {
      // "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + token,
    },
    body: formData,
  }).then((res) => res.json());
  console.log(formData);
  try {
    const result = await req;
    // console.log(result);
    return result;
  } catch (error) {
    console.log("error", error);
  }
};

const categoryListById = async (token = {}, id) => {
  const req = await fetch(
    `${process.env.REACT_APP_API_MEBZONE}/categories/public?mainCategoryId=${id}`,
    {
      headers: {
        // Authorization: "Bearer " + token,
      },
    }
  );
  try {
    const categoryList = await req.json();
    return categoryList;
  } catch (error) {
    console.log("error", error);
  }
};

const subCategoryListById = async (token = {}, id) => {
  const req = await fetch(
    `${process.env.REACT_APP_API_MEBZONE}/subcategories/public?categoryId=${id}`,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
  try {
    const subCategoryList = await req.json();
    return subCategoryList;
  } catch (error) {
    console.log("error", error);
  }
};

const isExistsFilters = async (id) => {
  const req = await fetch(
    `${process.env.REACT_APP_API_MEBZONE}/filters/exists?categoryId=${id}`
  );
  try {
    const isExists = await req.json();
    return isExists;
  } catch (err) {
    console.log("error", err);
  }
};

const getCategoryWithOnlyFilters = async (token, categoryId, productId) => {
  let baseUrl;
  if (productId) {
    baseUrl = `${process.env.REACT_APP_API_MEBZONE}/productattributes?categoryId=${categoryId}&productId=${productId}`;
  } else {
    baseUrl = `${process.env.REACT_APP_API_MEBZONE}/productattributes?categoryId=${categoryId}`;
  }

  const req = await fetch(baseUrl, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  try {
    const categoryFilterList = await req.json();
    return categoryFilterList;
  } catch (error) {
    console.log("error", error);
  }
};

const postProduct = async (data = {}, token) => {
  const req = await fetch(`${process.env.REACT_APP_API_MEBZONE}/products`, {
    method: "POST",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json; charset= UTF-8",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(data),
  });
  try {
    const result = await req;
    return result;
  } catch (error) {
    console.log("error", error);
  }
};

const postProductAttribute = async (data = {}, token) => {
  // console.log(data);
  const req = await fetch(
    `${process.env.REACT_APP_API_MEBZONE}/productattributes`,
    {
      method: "POST",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json; charset= UTF-8",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({ attributes: data }),
    }
  );
  try {
    const result = await req;
    return result;
  } catch (error) {
    console.log("error", error);
  }
};

const getStoreProducts = async (token = {}, storeId) => {
  var baseUrl;
  if (storeId) {
    baseUrl = `${process.env.REACT_APP_API_MEBZONE}/products?storeId=${storeId}`;
  } else {
    baseUrl = `${process.env.REACT_APP_API_MEBZONE}/products`;
  }
  const req = await fetch(baseUrl, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  try {
    const productList = await req.json();
    return productList;
  } catch (error) {
    console.log("error", error);
  }
};

const getProduct = async (token = {}, productId) => {
  const req = await fetch(
    `${process.env.REACT_APP_API_MEBZONE}/products/${productId}`,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
  try {
    const product = await req.json();
    return product;
  } catch (error) {
    console.log("error", error);
  }
};

const putActivateProduct = async (data = {}, token = {}, productId) => {
  const req = await fetch(
    `${process.env.REACT_APP_API_MEBZONE}/products/${productId}`,
    {
      method: "PUT",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json; charset= UTF-8",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    }
  );
  try {
    const result = await req;
    return result;
  } catch (error) {
    console.log("error", error);
  }
};

const putBlockProduct = async (data = {}, token = {}, productId) => {
  const req = await fetch(
    `${process.env.REACT_APP_API_MEBZONE}/products/${productId}/block`,
    {
      method: "PUT",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json; charset= UTF-8",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    }
  );
  try {
    const result = await req;
    return result;
  } catch (error) {
    console.log("error", error);
  }
};

const updateProduct = async (token, data, productId) => {
  console.log(data)
  const req = await fetch(
    `${process.env.REACT_APP_API_MEBZONE}/products/${productId}`,
    {
      method: "POST",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json; charset= UTF-8",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    }
  );
  try {
    const result = await req;
    return result;
  } catch (error) {
    console.log("error", error);
  }
};

const updateProductAttribute = async (token, data) => {
  const req = await fetch(
    `${process.env.REACT_APP_API_MEBZONE}/productattributes`,
    {
      method: "PUT",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json; charset= UTF-8",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({ attributes: data }),
    }
  );
  try {
    const result = await req;
    return result;
  } catch (error) {
    console.log("error", error);
  }
};


const getFilteredProducts = async (token, storeId, isBlocked, statusId) => {
  console.log(statusId, isBlocked)
  let baseUrl;
  if (isBlocked !== null && statusId === null) {
    baseUrl = `${process.env.REACT_APP_API_MEBZONE}/products?storeId=${storeId}&isBlocked=${isBlocked}`
  }
  else if (isBlocked === null && statusId !== null) {
    baseUrl = `${process.env.REACT_APP_API_MEBZONE}/products?storeId=${storeId}&statusId=${statusId}`
  } 
  else{
    baseUrl = `${process.env.REACT_APP_API_MEBZONE}/products?storeId=${storeId}&isBlocked=${isBlocked}&statusId=${statusId}`
  }
  const req = await fetch(baseUrl, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });

  try {
    const productList = await req.json();
    return productList;
  } catch (error) {
    console.log("error", error);
  }
};


export {
  postImage,
  categoryListById,
  subCategoryListById,
  getCategoryWithOnlyFilters,
  postProduct,
  postProductAttribute,
  getStoreProducts,
  getProduct,
  putActivateProduct,
  putBlockProduct,
  isExistsFilters,
  updateProduct,
  updateProductAttribute,
  getFilteredProducts
};
