import React, { Component, Fragment } from "react";
import { withTranslate } from "react-redux-multilingual";
import { translate } from "react-redux-multilingual/lib/utils";
//images import
import man from "../../../assets/images/dashboard/man.png";
import { getOwnStore } from "../../fetch/stores";

export class User_menu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: "",
      storeId: "",
      storeInfo: "",
    };
  }

  componentWillMount() {
    const token = localStorage.getItem("token");
    const storeId = Number(localStorage.getItem("storeId"));
    if (storeId) {
      getOwnStore(token, storeId).then((info) => {
        this.setState({ storeInfo: info });
      });
    }
  }

  logoutBtn() {
    localStorage.removeItem("token");
    localStorage.removeItem("role");
    localStorage.removeItem("storeId");
    // window.location.href = `http://localhost:3000?fullyLogout=${true}`;
    window.location.href = `${
      process.env.REACT_APP_MEBZONE_UZ
    }?fullyLogout=${true}`;
  }

  render() {
    const {translate} = this.props
    return (
      <Fragment>
        <li className="onhover-dropdown">
          <div className="media align-items-center">
            <img
              className="align-self-center pull-right img-50 rounded-circle blur-up lazyloaded"
              style={{ height: "50px", objectFit: "cover" }}
              src={
                this.state.storeInfo ? this.state.storeInfo.image.imageUrl : man
              }
              alt="header-user"
            />
            <div className="dotted-animation">
              <span className="animate-circle"></span>
              <span className="main-circle"></span>
            </div>
          </div>
          <ul className="profile-dropdown onhover-show-div p-20 profile-dropdown-hover">
            <li>
              <a href={`${process.env.REACT_APP_MEBZONE_UZ}/user-profile`}>
                <i data-feather="user"></i>{translate("Edit_user_profile")}
              </a>
            </li>
            <li onClick={() => this.logoutBtn()}>
              <i data-feather="log-out"></i> {translate("Log_out")}
            </li>
          </ul>
        </li>
      </Fragment>
    );
  }
}

export default withTranslate(User_menu);
