import React, { Component, Fragment } from "react";
import { withTranslate } from "react-redux-multilingual";
import { connect } from "react-redux";
import moment from "moment";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import SimpleReactValidator from "simple-react-validator";
import Select from "react-select";
import Breadcrumb from "../../common/breadcrumb";
import Modal from "react-responsive-modal";
import ReactTable from "react-table";
import "react-toastify/dist/ReactToastify.css";
import "react-table/react-table.css";
import { ToastContainer, toast } from "react-toastify";
import { postImage } from "../../products/listingProducts/api";
import man from "../../../assets/images/dashboard/man.png";
import { getFitSize, readImageSize } from "../../common/crop";
import "./still.scss";
import ClipLoader from "react-spinners/ClipLoader";

import {
  mainCategoryList,
  getCategories,
  postCategory,
  updateCategory,
} from "../../fetch/categories";
import { filtersList } from "../../fetch/filters";
import "./still.scss";
import { archiveCategories } from "../../fetch/products";

export class Category extends Component {
  constructor(props) {
    super(props);
    this.attachPhotoRef = React.createRef();
    this.cropperRef = React.createRef();
    this.state = {
      token: "",
      myData: [],
      pages: null,
      loading: true,
      open: false,
      action: null,
      name_uz: "",
      name_ru: "",
      mainCategoryOptions: [],
      selectedMainCategory: null,
      mainCategoryId: null,
      multiFilterOptions: [],
      selectedMultiFilters: [],
      filters: [],
      id: null,
      index: null,
      indexes: null,
      toggle: null,
      active: null,
      imageId: null,
      blob: "",
      blobURL: "",
      openCrop: false,
      fileTypes: ["jpg", "jpeg", "png"],
      imageChanged: false,
      disableBtn1: false,
      disableBtn2: false
    };
    this.fetchData = this.fetchData.bind(this);
    this.handleInputs = this.handleInputs.bind(this);
  }

  componentWillMount() {
    this.validator = new SimpleReactValidator({
      messages: {
        required: this.props.translate("Fill_the_gap"),
      },
    });
    const token = localStorage.getItem("token");
    this.setState({ token: token })
    mainCategoryList(token).then((mlist) => {
      let newArray = [];
      mlist.map((m) => {
        newArray.push({ value: m.id, label: this.props.locale === "en" ? m.name_uz : m.name_ru });
      });
      this.setState({ mainCategoryOptions: newArray, token: token });
    });
    console.log("TOKEN", this.state.token);
    filtersList(token).then((flist) => {
      let newArray = [];
      flist.map((f) => {
        newArray.push({ value: f.id, label: f.name_ru });
      });
      this.setState({ multiFilterOptions: newArray });
    });
  }

  fetchData(state, instance) {
    this.setState({ loading: true });
    console.log("TOKEN", this.state.token);
    getCategories(this.state.token, state.page + 1, state.pageSize).then(
      async (res) => {
        if (res.status == 200) {
          const totalLength = res.headers.get("totalLength");
          const pages = Math.ceil(totalLength / state.pageSize);
          this.setState({ pages });
          const list = await res
            .clone()
            .json()
            .then((ulist) => {
              this.setState({
                myData: ulist,
                loading: false,
              });
            });
        }
      }
    );
  }

  onOpenModal = (type) => {
    this.setState({ open: true, action: type });
  };

  onCloseModal = () => {
    this.setState({
      open: false,
      action: null,
      name_uz: "",
      name_ru: "",
      selectedMainCategory: null,
      mainCategoryId: null,
      selectedMultiFilters: [],
      filters: [],
    });
    this.handleCancel();
  };

  handleInputs(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  handleSingleSelect = (selectedMainCategory) => {
    this.setState({ selectedMainCategory });
    if (selectedMainCategory) {
      this.setState({
        mainCategoryId: selectedMainCategory.value,
      });
    }
  };

  handleMultiSelect = (selectedMultiFilters) => {
    this.setState({ selectedMultiFilters });
    this.state.filters = [];
    selectedMultiFilters.map((el) => {
      this.state.filters.push(el.value);
    });
  };

  toggleClass = (id) => {
    this.setState({ toggle: id });
    if (this.state.toggle === id) {
      this.setState({ toggle: null });
    }
  };

  toggleActive = (id) => {
    this.setState({ active: id });
    if (this.state.active === id) {
      this.setState({ active: null });
    }
  };

  addCategoryBtn = (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      this.setState({disableBtn1: true})
      const { token, name_uz, name_ru, mainCategoryId, filters, blob } =
        this.state;
      postImage(blob).then((res) => {
        const finalArray = [
          { name_uz, name_ru, mainCategoryId, filters, imageId: res.id },
        ];
        postCategory(token, finalArray).then(async (res) => {
          if (res.status === 200) {
            const r = await res.clone().json();
            this.setState({
              myData: [...r, ...this.state.myData],
              imageChanged: false,
            });
            setTimeout(() => {
              this.setState({ disableBtn1: false });
              this.onCloseModal();
              toast.success(this.props.translate("Successfully_added"));
            }, 2000);
          } else {
            console.log("wrong status with add category");
          }
        });
      });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  updateCategoryBtn = (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      this.setState({disableBtn2: true})
      const { token, name_uz, name_ru, mainCategoryId, filters, id, imageId } =
        this.state;
      if (this.state.imageChanged) {
        postImage(this.state.blob).then((res) => {
          const finalObject = {
            name_uz,
            name_ru,
            mainCategoryId,
            filters,
            imageId: res.id,
          };

          updateCategory(token, id, finalObject).then(async (res) => {
            if (res.status === 200) {
              const r = await res.clone().json();
              //console.log(r);
              const newData = this.state.myData.filter((d) => d.id != id);
              const sortedData = [r, ...newData].sort(function (a, b) {
                return a.id - b.id;
              });
              this.setState({
                myData: sortedData,
                imageChanged: false,
              });
              setTimeout(() => {
                this.setState({ disableBtn2: false });
                this.onCloseModal();
                toast.success(this.props.translate("Successfully_updated"));
              }, 2000);
            } else {
              console.log("wrong status with update category");
            }
          });
        });
      } else {
        const finalObj = { name_uz, name_ru, mainCategoryId, filters, imageId };
        updateCategory(token, id, finalObj).then(async (res) => {
          if (res.status === 200) {
            const r = await res.clone().json();
            //console.log(r);
            const newData = this.state.myData.filter((d) => d.id != id);
            const sortedData = [r, ...newData].sort(function (a, b) {
              return a.id - b.id;
            });
            this.setState({
              myData: sortedData,
              imageChanged: false,
            });
            setTimeout(() => {
              this.setState({ disableBtn2: false });
              this.onCloseModal();
              toast.success(this.props.translate("Successfully_updated"));
            }, 2000);
          } else {
            console.log("wrong status with update category");
          }
        });
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  // cropping

  handleCancel = () => {
    this.setState({
      openCrop: false,
      blob: "",
      blobURL: "",
      imageChanged: false,
    });
  };
  handleDone = () => {
    const cropper = this.cropperRef.current.cropper;
    if (!cropper) return;
    cropper.getCroppedCanvas().toBlob((blob) => {
      const blobURL = URL.createObjectURL(blob);

      this.setState({
        blob,
        blobURL,
        imageChanged: true,
      });
    }, "image/jpeg");

    this.handleCancel();
  };

  handleAttach = () => {
    this.attachPhotoRef.current.click();
  };
  archive = (e, id) => {
    e.preventDefault();
    const data = {
      isArchived: true
    }
    archiveCategories(this.state.token, id, data).then((res)=>{
      console.log(res);
      if (res.status == 200) {
        window.location.reload()
      }
    })
  }
  unArchive = (e, id) => {
    e.preventDefault();
    const data = {
      isArchived: false
    }
    archiveCategories(this.state.token, id, data).then((res)=>{
      console.log(res)
      if (res.status == 200) {
        window.location.reload()
      }
    })
  }

  handleAttachComplete = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    if (file) {
      var extension = file.name.split(".").pop().toLowerCase();
      var isSuccess = this.state.fileTypes.indexOf(extension) > -1;
    }
    if (isSuccess) {
      reader.onloadend = async () => {
        const [width, height] = await readImageSize(file);

        this.setState({
          openCrop: true,
          file,
          fileURL: URL.createObjectURL(file),
          width,
          height,
        });
        this.attachPhotoRef.current.value = "";
      };
      reader.readAsDataURL(file);
    } else {
      console.log("error image");
    }
  };

  render() {
    const {translate, locale} = this.props
    const { open, openCrop, width, height, fileURL, blobURL } = this.state;
    const { width: fitWidth, height: fitHeight } = getFitSize(
      { width, height },
      400,
      true
    );
    const columns = [
      {
        Header: "ID",
        accessor: "id",
        style: {
          textAlign: "center",
        },
      },
      {
        Header: translate("name"),
        accessor: locale === "en" ? "name_uz" : "name_ru",
        style: {
          textAlign: "center",
        },
      },
      {
        id: "imageId",
        Header: translate("wallpaper"),
        accessor: (row) => {
          return (
            <img
              style={{ height: "65px", width: "130px" }}
              src={row.image ? row.image.imageUrl : man}
              alt="main-category-img"
            />
          );
        },
        style: {
          textAlign: "center",
        },
        sortable: false,
        filterable: false,
      },

      {
        id: "mainCategory",
        Header: translate("Main_category"),
        accessor: (row) => {
          return  locale === "en" ? row.mainCategory.name_uz : row.mainCategory.name_ru;
        },
        style: {
          textAlign: "center",
        },
      },
      {
        id: "subCategories",
        Header: translate("Sub_categories"),
        accessor: (row) => {
          if (row.subCategories) {
            return (
              <div>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    this.toggleActive(row.id);
                  }}
                >
                  {this.state.active === row.id ? (
                    <i className="fa fa-caret-down"></i>
                  ) : (
                    <i className="fa fa-caret-right"></i>
                  )}{" "}
                  {translate("sub")}({row.subCategories.length})
                </span>
                <ul
                  className={
                    this.state.active === row.id
                      ? "showClass category-filter-list"
                      : "category-filter-list"
                  }
                >
                  {row.subCategories.map((s, index) => (
                    <li key={index}>{s.name_ru}</li>
                  ))}
                </ul>
              </div>
            );
          } else {
            return "";
          }
        },
        style: {
          textAlign: "center",
        },
        sortable: false,
        filterable: false,
      },
      {
        id: "filters",
        Header: translate("Filters"),
        Cell: (row) => {
          return row.original.filters ? (
            <div>
              <span
                style={{ cursor: "pointer" }}
                onClick={() => this.toggleClass(row.original.id)}
              >
                {this.state.toggle === row.original.id ? (
                  <i className="fa fa-caret-down"></i>
                ) : (
                  <i className="fa fa-caret-right"></i>
                )}{" "}
                {translate("Filters")}({row.original.filters.length})
              </span>
              <ul
                className={
                  this.state.toggle === row.original.id
                    ? "showClass category-filter-list"
                    : "category-filter-list"
                }
              >
                {row.original.filters.map((f, index) => (
                  <li key={index}>{f.name_ru}</li>
                ))}
              </ul>
            </div>
          ) : (
            ""
          );
        },

        style: {
          textAlign: "center",
        },
        sortable: false,
        filterable: false,
      },
      {
        id: "createdAt",
        Header: translate("Time_created"),
        accessor: (row) => {
          return moment(row.createdAt).format("DD/MM/YYYY");
        },
        style: {
          textAlign: "center",
        },
        filterable: false,
      },
      {
        id: "updatedAt",
        Header: translate("Time_updated"),
        accessor: (row) => {
          return moment(row.updatedAt).format("DD/MM/YYYY");
        },
        style: {
          textAlign: "center",
        },
        filterable: false,
      },
      {
        Header: "Archived",
        Cell: (row) => (
          <div>
            {!row.original.isArchived ? <span>
              not archived
            </span> : <span>
              archived
            </span>}
          </div>
        ),
        style: {
          textAlign: "center",
        },
        sortable: false,
        filterable: false,
      },
      {
        Header: <b>Action</b>,
        Cell: (row) => (
          <div>
            {!row.original.isArchived ? <span
              onClick={() => {
                //console.log(row);
                const chosenMainCategory = this.state.mainCategoryOptions.find(
                  (x) => x.value === row.original.mainCategoryId
                );
                const index =
                  this.state.mainCategoryOptions.indexOf(chosenMainCategory);

                const chosenFilters = [];
                row.original.filters.map((f) => {
                  chosenFilters.push(
                    this.state.multiFilterOptions.find((x) => x.value === f.id)
                  );
                });
                const indexes = [];
                chosenFilters.map((x) => {
                  indexes.push(this.state.multiFilterOptions.indexOf(x));
                });
                // console.log(indexes);
                this.setState({
                  id: row.original.id,
                  name_uz: row.original.name_uz,
                  name_ru: row.original.name_ru,
                  index: index,
                  selectedMainCategory: chosenMainCategory,
                  mainCategoryId: chosenMainCategory.value,
                  selectedMultiFilters: chosenFilters,
                  filters: chosenFilters.map((f) => {
                    return f.value;
                  }),
                  indexes: indexes,
                  imageId: row.original.imageId,
                  blobURL: row.original.image
                    ? row.original.image.imageUrl
                    : "",
                });
                this.onOpenModal("edit");
              }}
            >
              <i
                className="fa fa-pencil"
                style={{
                  width: 35,
                  fontSize: 20,
                  padding: 11,
                  color: "rgb(40, 167, 69)",
                }}
              ></i>
            </span> : ""}
            
            <span
                     onClick={(e) => {!row.original.isArchived ?
                      this.archive(e, row.original.id) : this.unArchive(e, row.original.id)
                    }}>
                <i
                  className="fa fa-file-archive-o"
                  style={{
                    width: 35,
                    fontSize: 20,
                    padding: 10,
                    color: "rgb(40, 167, 69)",
                  }}
                ></i>
              </span>
          </div>
        ),

        style: {
          textAlign: "center",
          cursor: "pointer",
        },
        sortable: false,
        filterable: false,
      },
    ];

    return (
      <Fragment>
        <Breadcrumb title={translate("Category")} parent={translate("Product_classifications")} />
        {/* <!-- Container-fluid starts--> */}
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <h5>{translate("Product_category")}</h5>
                </div>
                <div className="card-body">
                  <div className="btn-popup pull-right">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => this.onOpenModal("add")}
                      data-toggle="modal"
                      data-original-title="test"
                      data-target="#exampleModal"
                    >
                      {translate("add_category")}
                    </button>
                    <Modal
                      open={open}
                      onClose={this.onCloseModal}
                      closeOnOverlayClick={false}
                    >
                      <div className="modal-header">
                        <h5
                          className="modal-title f-w-600"
                          id="exampleModalLabel2"
                        >
                          {this.state.action == "add"
                            ? translate("add_category")
                            : translate("update_category")}
                        </h5>
                      </div>
                      <div className="modal-body">
                        <form>
                          <div className="form-group">
                            <label className="col-form-label">
                              {translate("category_name")}:
                            </label>
                            <input
                              type="text"
                              name="name_uz"
                              value={this.state.name_uz}
                              placeholder={
                                this.state.action == "edit"
                                  ? this.state.name_uz
                                  : translate("In_uzbek")
                              }
                              onChange={this.handleInputs}
                              className="form-control"
                              onBlur={() =>
                                this.validator.showMessageFor("name_uz")
                              }
                            />
                            {this.validator.message(
                              "name_uz",
                              this.state.name_uz,
                              "required"
                            )}
                            <label className="col-form-label">
                              {translate("category_name")}:
                            </label>
                            <input
                              type="text"
                              name="name_ru"
                              value={this.state.name_ru}
                              placeholder={
                                this.state.action == "edit"
                                  ? this.state.name_ru
                                  : translate("In_russian")
                              }
                              onChange={this.handleInputs}
                              className="form-control"
                              onBlur={() =>
                                this.validator.showMessageFor("name_ru")
                              }
                            />
                            {this.validator.message(
                              "name_ru",
                              this.state.name_ru,
                              "required"
                            )}
                            <label
                              htmlFor="recipient-name"
                              className="col-form-label"
                            >
                              {translate("Select_main_category")}
                            </label>

                            <Select
                              defaultValue={
                                this.state.action == "edit"
                                  ? this.state.mainCategoryOptions[
                                      this.state.index
                                    ]
                                  : ""
                              }
                              className="basic-single"
                              classNamePrefix="select"
                              name="color"
                              options={this.state.mainCategoryOptions}
                              placeholder={translate("Select_main_category")}
                              onChange={this.handleSingleSelect}
                              isClearable="false"
                              isSearchable="true"
                              onBlur={() =>
                                this.validator.showMessageFor(
                                  "selectedMainCategory"
                                )
                              }
                            />
                            {this.validator.message(
                              "selectedMainCategory",
                              this.state.selectedMainCategory,
                              "required"
                            )}

                            <label
                              htmlFor="recipient-name"
                              className="col-form-label"
                            >
                              {translate("Select_filters")}
                            </label>
                            <Select
                              defaultValue={
                                this.state.action == "edit"
                                  ? this.state.indexes.map((i) => {
                                      return this.state.multiFilterOptions[i];
                                    })
                                  : ""
                              }
                              isMulti
                              name="colors"
                              options={this.state.multiFilterOptions}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              placeholder={translate("Select_filters")}
                              value={this.state.selectedMultiFilters}
                              onChange={this.handleMultiSelect}
                              // onBlur={() =>
                              //   this.validator.showMessageFor(
                              //     "selectedMultiFilters"
                              //   )
                              // }
                            />
                            {/* {this.validator.message(
                              "selectedMultiFilters",
                              this.state.selectedMultiFilters,
                              "required"
                            )} */}
                            <div>
                              <label className="col-form-label">
                                {translate("wallpaper")}:
                              </label>
                              <div className="new-chat-photo">
                                {blobURL ? (
                                  <img
                                    className="new-chat-photo-image-button-category"
                                    alt=""
                                    src={blobURL}
                                    onClick={this.handleAttach}
                                  />
                                ) : (
                                  <div
                                    className="new-chat-photo-image-button-category new-chat-photo-button"
                                    onClick={this.handleAttach}
                                  >
                                    <i
                                      className="fa fa-plus"
                                      aria-hidden="true"
                                    ></i>
                                  </div>
                                )}
                              </div>

                              <input
                                style={{ display: "none" }}
                                ref={this.attachPhotoRef}
                                type="file"
                                multiple={false}
                                accept="image/*"
                                onChange={(e) => this.handleAttachComplete(e)}
                                onBlur={() =>
                                  this.validator.showMessageFor("category_img")
                                }
                              />
                              {this.validator.message(
                                "category_img",
                                this.state.blobURL,
                                "required"
                              )}
                              {openCrop && (
                                <div>
                                  <div>
                                    <Cropper
                                      ref={this.cropperRef}
                                      src={fileURL}
                                      style={{
                                        height: fitHeight,
                                        width: fitWidth,
                                      }}
                                      aspectRatio={3 / 2}
                                      cropBoxResizable={false}
                                      viewMode={3}
                                      guides={true}
                                    />
                                  </div>
                                  <div className="crop-btns">
                                    <button onClick={this.handleCancel}>
                                      <i
                                        style={{ color: "red" }}
                                        className="fa fa-close"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                    <button onClick={this.handleDone}>
                                      <i
                                        style={{ color: "#00a075" }}
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </form>
                      </div>
                      <div className="modal-footer">
                        {this.state.action == "add" ? (
                          <button
                            type="button"
                            disabled={this.state.disableBtn1}
                            style={{
                            cursor: this.state.disableBtn1 ? "not-allowed" : "",
                            }}
                            className="btn btn-primary"
                            onClick={(event) => this.addCategoryBtn(event)}
                          >
                            <span
                             style={{
                               display: "flex",
                               justifyContent: "center",
                               alignItems: "center",
                             }}
                            >
                              <ClipLoader
                               color={"#fff"}
                               loading={this.state.disableBtn1}
                               size={20}
                              />
                              <span className="ml-1">
                                {translate("Add")}
                              </span>
                            </span>
                          </button>
                        ) : (
                          <button
                            type="button"
                            disabled={this.state.disableBtn2}
                            style={{
                            cursor: this.state.disableBtn2 ? "not-allowed" : "",
                            }}
                            className="btn btn-primary"
                            onClick={(event) => this.updateCategoryBtn(event)}
                          >
                            <span
                             style={{
                               display: "flex",
                               justifyContent: "center",
                               alignItems: "center",
                             }}
                            >
                              <ClipLoader
                               color={"#fff"}
                               loading={this.state.disableBtn2}
                               size={20}
                              />
                              <span className="ml-1">
                                {translate("Update")}
                              </span>
                            </span>
                          </button>
                        )}

                        <button
                          type="button"
                          className="btn btn-secondary"
                          onClick={() => this.onCloseModal("VaryingMdo")}
                        >
                          {translate("Cancel")}
                        </button>
                      </div>
                    </Modal>
                  </div>
                  <div className="clearfix"></div>
                  <div id="basicScenario" className="product-physical">
                    <ReactTable
                      manual
                      multiSelectOption={false}
                      defaultPageSize={10}
                      pages={this.state.pages}
                      data={this.state.myData}
                      columns={columns}
                      showPagination={true}
                      className="-striped -highlight"
                      onFetchData={this.fetchData}
                      filterable
                      previousText={translate("Previous")}
                      nextText={translate("Next")}
                      pageText={translate("Page")}
                      rowsText={translate("Rows")}
                    />
                    <ToastContainer />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Container-fluid Ends--> */}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  locale: state.Intl.locale,
});

export default connect(mapStateToProps)(withTranslate(Category));
