import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withTranslate,  IntlActions } from "react-redux-multilingual";
import store from "../../../redux/store";

export class Language extends Component {
  
  changeLanguage(lang) {
    store.dispatch(IntlActions.setLocale(lang));
  }
  
  render() {
    const {translate} = this.props
    return (
      <Fragment>
        <ul className="language-dropdown onhover-show-div p-20">
          <li>
            <a href="javascript:void(0)" data-lng="uz" onClick={() =>this.changeLanguage("en")} >
              <i className="flag-icon flag-icon-uz"></i>{translate("Uzbek")}
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" data-lng="ru" onClick={() => this.changeLanguage("ru")} >
              <i className="flag-icon flag-icon-ru"></i>{translate("Russian")}
            </a>
          </li>
          {/* <li><a href="javascript:void(0)" data-lng="en"><i className="flag-icon flag-icon-is"></i> English</a></li>
                    <li><a href="javascript:void(0)" data-lng="es"><i className="flag-icon flag-icon-um"></i> Spanish</a></li>
                    <li><a href="javascript:void(0)" data-lng="pt"><i className="flag-icon flag-icon-uy"></i> Portuguese</a></li>
                    <li><a href="javascript:void(0)" data-lng="fr"><i className="flag-icon flag-icon-nz"></i> French</a></li> */}
        </ul>
      </Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
 
});

export default  connect(mapStateToProps)(withTranslate(Language));
