import React, { Component, Fragment } from "react";
import { withTranslate } from "react-redux-multilingual";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Breadcrumb from "../common/breadcrumb";
import "../settings/categories/still.scss";
import { getAllStores } from "../fetch/stores";
import man from "../../assets/images/dashboard/man.png";
export class OwnStoresList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: "",
      ownStoreList: [],
    };
  }

  componentWillMount() {
    const token = localStorage.getItem("token");
    const storeId = localStorage.getItem("storeId");
    getAllStores(token).then((slist) => {
      // console.log(slist);
      var newlist = slist.filter((el) => el.id != storeId);
      this.setState({ ownStoreList: [...newlist], token: token });
    });
  }

  render() {
    const {translate, locale} = this.props
     if(this.state.ownStoreList.length === 0){
        return(
          <Fragment>
          <Breadcrumb title={translate("My_stores")} parent={translate("My_stores")} />
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-header">
                    <p>{translate("you_have_only_one_store")}</p>
                    <a className="btn btn-primary" href={`${process.env.REACT_APP_MEBZONE_UZ}/user-profile`}>{translate("My_profile")}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
        )
     }
    else{
    return (
      <Fragment>
        <Breadcrumb title={translate("My_stores")} parent={translate("My_stores")} />
        <div className="row products-admin ratio_asos">
          {this.state.ownStoreList.map((store, i) => {
            return (
              <div className="col-xl-3 col-sm-6" key={i}>
                <div className="card">
                  <div className="products-admin">
                    <div className="card-body product-box">
                      <div className="img-wrapper">
                        <div className="front">
                          <a className="bg-size">
                            <img
                              className="img-fluid blur-up bg-img lazyloaded store-logo-img"
                              src={store.image ? store.image.imageUrl : man}
                            />
                          </a>
                        </div>
                      </div>
                      <div className="store-info">
                        <p>
                          {translate("Company_name")}: <span> {store.brandname}</span>{" "}
                        </p>
                        <p>
                          {translate("Address")}: <span> {store.address} </span>
                        </p>
                        <p>
                          {translate("Region")}: <span> {locale === "en" ? store.region.name_uz : store.region.name_ru} </span>
                        </p>
                        <p>
                          {translate("phone")}: <span> {store.phone} </span>{" "}
                        </p>
                        <button
                          disabled={store.statusId === 1}
                          className="btn btn-primary see-more-btn"
                          onClick={() => {
                            localStorage.setItem("storeId", store.id);
                            this.props.history.push(
                              `${process.env.PUBLIC_URL}/product-list`
                            );
                            window.location.reload();
                          }}
                        >
                          {store.statusId === 1
                            ? translate("you_are_not_activated")
                            : translate("Login")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </Fragment>
    );
    }
  }
}


const mapStateToProps = (state, ownProps) => ({
  locale: state.Intl.locale,
});
export default connect(mapStateToProps)(withRouter(withTranslate(OwnStoresList)));
