import React, { Component } from "react";

export class Footer extends Component {
  render() {
    return (
      <div>
        <footer className="footer">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 footer-copyright">
                <p className="mb-0">Mebzone</p>
              </div>
              <div className="col-md-6">
                <p className="pull-right mb-0">We Make</p>
              </div>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}

export default Footer;
