import React, { Component, Fragment } from "react";
import { withTranslate } from "react-redux-multilingual";
import { connect } from "react-redux";
import User_panel from "./user-panel";
import { Link } from "react-router-dom";
import { MENUITEMS } from "../../../constants/menu";

// image import
import logo from "../../../assets/images/dashboard/mebzone-logo.svg";

export class sidebar extends Component {
  state = { selectedPath: "1", mainmenu: [] };
  onItemSelection = (arg, e) => {
    this.setState({ selectedPath: arg.path });
  };

  
  componentWillMount() {
    const role = localStorage.getItem("role");
    const chosenMenuItems = MENUITEMS.filter((el) => {
      return el.role == role;
    });
    this.setState({
      mainmenu: chosenMenuItems,
    });
  }
  componentDidMount() {
    var currentUrl = window.location.pathname;

    this.state.mainmenu.filter((items) => {
      if (!items.children) {
        if (items.path === currentUrl) this.setNavActive(items);
        return false;
      }
      items.children.filter((subItems) => {
        if (subItems.path === currentUrl) this.setNavActive(subItems);
        if (!subItems.children) return false;
        subItems.children.filter((subSubItems) => {
          if (subSubItems.path === currentUrl) this.setNavActive(subSubItems);
        });
      });
    });
  }

  setNavActive(item) {
    const role = localStorage.getItem("role");

    const chosenMenuItems = MENUITEMS.filter((el) => {
      return el.role == role;
    });

    chosenMenuItems.filter((menuItem) => {
      if (menuItem != item) menuItem.active = false;
      if (menuItem.children && menuItem.children.includes(item))
        menuItem.active = true;
      if (menuItem.children) {
        menuItem.children.filter((submenuItems) => {
          if (submenuItems != item) {
            submenuItems.active = false;
          }
          if (submenuItems.children) {
            submenuItems.children.map((childItem) => {
              childItem.active = false;
            });
            if (submenuItems.children.includes(item)) {
              submenuItems.active = true;
              menuItem.active = true;
            }
          }
        });
      }
    });
    item.active = !item.active;

    this.setState({
      mainmenu: chosenMenuItems,
    });
  }

  render() {
    const {locale} = this.props
    const theme = {
      selectionColor: "#C51162",
    };

    const mainmenu = this.state.mainmenu.map((menuItem, i) => (
      <li className={`${menuItem.active ? "active" : ""}`} key={i}>
        {menuItem.sidebartitle ? (
          <div className="sidebar-title">{menuItem.sidebartitle}</div>
        ) : (
          ""
        )}
        {menuItem.type === "sub" ? (
          <a
            className="sidebar-header "
            href="javascript:void(0)"
            onClick={() => this.setNavActive(menuItem)}
          >
            <menuItem.icon />
            <span>{locale === "en" ? menuItem.title_uz : menuItem.title}</span>
            <i className="fa fa-angle-right pull-right"></i>
          </a>
        ) : (
          ""
        )}
        {menuItem.type === "link" ? (
          <Link
            to={`${process.env.PUBLIC_URL}${menuItem.path}`}
            className={`sidebar-header ${menuItem.active ? "active" : ""}`}
            onClick={() => {
              this.setNavActive(menuItem);
              // By mavluda
              if (
                document
                  .querySelector(".page-main-header")
                  .classList.contains("open") &&
                document
                  .querySelector(".page-sidebar")
                  .classList.contains("open")
              ) {
                document
                  .querySelector(".page-main-header")
                  .classList.remove("open");
                document
                  .querySelector(".page-sidebar")
                  .classList.remove("open");
              }
              // end by Mavluda
            }}
          >
            <menuItem.icon />
            <span>{locale === 'en' ? menuItem.title_uz : menuItem.title}</span>
            {menuItem.children ? (
              <i className="fa fa-angle-right pull-right"></i>
            ) : (
              ""
            )}
          </Link>
        ) : (
          ""
        )}
        {menuItem.children ? (
          <ul
            className={`sidebar-submenu ${menuItem.active ? "menu-open" : ""}`}
            style={
              menuItem.active
                ? { opacity: 1, transition: "opacity 500ms ease-in" }
                : {}
            }
          >
            {menuItem.children.map((childrenItem, index) => (
              <li
                key={index}
                className={
                  childrenItem.children
                    ? childrenItem.active
                      ? "active"
                      : ""
                    : ""
                }
              >
                {childrenItem.type === "sub" ? (
                  <a
                    href="javascript:void(0)"
                    onClick={() => this.setNavActive(childrenItem)}
                  >
                    <i className="fa fa-circle"></i>
                    {childrenItem.title}{" "}
                    <i className="fa fa-angle-right pull-right"></i>
                  </a>
                ) : (
                  ""
                )}

                {childrenItem.type === "link" ? (
                  <Link
                    to={`${process.env.PUBLIC_URL}${childrenItem.path}`}
                    className={childrenItem.active ? "active" : ""}
                    onClick={() => {
                      this.setNavActive(childrenItem);
                      // By mavluda
                      if (
                        document
                          .querySelector(".page-main-header")
                          .classList.contains("open") &&
                        document
                          .querySelector(".page-sidebar")
                          .classList.contains("open")
                      ) {
                        document
                          .querySelector(".page-main-header")
                          .classList.remove("open");
                        document
                          .querySelector(".page-sidebar")
                          .classList.remove("open");
                      }
                      // end by Mavluda
                    }}
                  >
                    <i className="fa fa-circle"></i>
                    {locale === "en" ? childrenItem.title_uz : childrenItem.title}{" "}
                  </Link>
                ) : (
                  ""
                )}
                {childrenItem.children ? (
                  <ul
                    className={`sidebar-submenu ${
                      childrenItem.active ? "menu-open" : "active"
                    }`}
                  >
                    {childrenItem.children.map((childrenSubItem, key) => (
                      <li
                        className={childrenSubItem.active ? "active" : ""}
                        key={key}
                      >
                        {childrenSubItem.type === "link" ? (
                          <Link
                            to={`${process.env.PUBLIC_URL}${childrenSubItem.path}`}
                            className={childrenSubItem.active ? "active" : ""}
                            onClick={() => this.setNavActive(childrenSubItem)}
                          >
                            <i className="fa fa-circle"></i>
                            {childrenSubItem.title}
                          </Link>
                        ) : (
                          ""
                        )}
                      </li>
                    ))}
                  </ul>
                ) : (
                  ""
                )}
              </li>
            ))}
          </ul>
        ) : (
          ""
        )}
      </li>
    ));

    return (
      <Fragment>
        <div className="page-sidebar">
          <div className="main-header-left d-none d-lg-block">
            <div className="logo-wrapper">
              <a href={`${process.env.REACT_APP_MEBZONE_UZ}`}>
                <img className="blur-up lazyloaded" src={logo} alt="" />
              </a>
            </div>
          </div>
          <div className="sidebar custom-scrollbar">
            <User_panel />
            <ul className="sidebar-menu">{mainmenu}</ul>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  locale: state.Intl.locale,
});
export default connect(mapStateToProps)(withTranslate(sidebar));
