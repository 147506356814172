import React, { Component, Fragment } from "react";
import { withTranslate } from "react-redux-multilingual";
import { connect } from "react-redux";
import moment from "moment";
import SimpleReactValidator from "simple-react-validator";
import Breadcrumb from "../../common/breadcrumb";
import Modal from "react-responsive-modal";
import ReactTable from "react-table";
import "react-toastify/dist/ReactToastify.css";
import "react-table/react-table.css";
import { ToastContainer, toast } from "react-toastify";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { getFitSize, readImageSize } from "../../common/crop";
import { postImage } from "../../products/listingProducts/api";
import man from "../../../assets/images/dashboard/man.png";
import ClipLoader from "react-spinners/ClipLoader";
import {
  getMainCategories,
  postMainCategory,
  updateMainCategory,
} from "../../fetch/categories";
import "./still.scss";
import { archiveMaincategories } from "../../fetch/products";

export class MainCategory extends Component {
  constructor(props) {
    super(props);
    this.attachPhotoRef = React.createRef();
    this.cropperRef = React.createRef();
    this.state = {
      token: "",
      myData: [],
      pages: null,
      loading: true,
      open: false,
      action: null,
      name_uz: "",
      description_uz: "",
      name_ru: "",
      description_ru: "",
      imageId: null,
      blob: "",
      blobURL: "",
      openCrop: false,
      fileTypes: ["jpg", "jpeg", "png"],
      imageChanged: false,
      button_uz: "",
      button_ru: "",
      disableBtn1: false,
      disableBtn2: false,
    };
    this.fetchData = this.fetchData.bind(this);
    this.handleInputs = this.handleInputs.bind(this);
  }

  componentWillMount() {
    this.validator = new SimpleReactValidator({
      messages: {
        required: this.props.translate("Fill_the_gap"),
      },
    });
    const token = localStorage.getItem("token");
    this.setState({ token: token });
  }

  fetchData(state, instance) {
    this.setState({ loading: true });
    getMainCategories(this.state.token, state.page + 1, state.pageSize).then(
      async (res) => {
        if (res.status == 200) {
          const totalLength = res.headers.get("totalLength");
          const pages = Math.ceil(totalLength / state.pageSize);
          this.setState({ pages });
          const list = await res
            .clone()
            .json()
            .then((ulist) => {
              this.setState({
                myData: ulist,
                loading: false,
              });
            });
        }
      }
    );
  }

  onOpenModal = (type) => {
    this.setState({ open: true, action: type });
  };

  onCloseModal = () => {
    this.setState({
      open: false,
      action: null,
      name_uz: "",
      description_uz: "",
      name_ru: "",
      description_ru: "",
      button_uz: "",
      button_ru: ""
    });
    this.handleCancel();
    this.validator.hideMessages()

  };

  handleInputs(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  addMaincategoryBtn = (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      this.setState({disableBtn1: true})
      const { token, name_uz, description_uz, name_ru, description_ru, blob, button_uz, button_ru } =
        this.state;
      postImage(blob).then((res) => {
        const finalArray = [
          { name_uz, description_uz, name_ru, description_ru, imageId: res.id, button_uz, button_ru },
        ];
        postMainCategory(token, finalArray).then(async (res) => {
          if (res.status === 200) {
            const r = await res.clone().json();
            this.setState({
              myData: [...r, ...this.state.myData],
              imageChanged: false,
            });
            setTimeout(() => {
              this.setState({ disableBtn1: false });
              this.onCloseModal();
              toast.success(this.props.translate("Successfully_added"));
            }, 2000);
          } else {
            console.log("wrong status with main category");
          }
        });
      });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  updateMaincategoryBtn = (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      this.setState({disableBtn2: true})
      const {
        token,
        id,
        name_uz,
        description_uz,
        name_ru,
        description_ru,
        imageId,
        button_uz,
        button_ru
      } = this.state;
      if (this.state.imageChanged) {
        postImage(this.state.blob).then((res) => {
          const finalObject = {
            name_uz,
            description_uz,
            name_ru,
            description_ru,
            imageId: res.id,
            button_uz,
            button_ru
          };
          updateMainCategory(token, id, finalObject).then(async (res) => {
            if (res.status === 200) {
              const r = await res.clone().json();
              const newData = this.state.myData.filter((d) => d.id != id);
              const sortedData = [r, ...newData].sort(function (a, b) {
                return a.id - b.id;
              });
              this.setState({
                myData: sortedData,
                imageChanged: false,
              });
              setTimeout(() => {
                this.setState({ disableBtn2: false });
                this.onCloseModal();
                toast.success(this.props.translate("Successfully_updated"));
              }, 2000);
            } else {
              console.log("wrong status with main category");
            }
          });
        });
      } else {
        const finalObj = {
          name_uz,
          description_uz,
          name_ru,
          description_ru,
          imageId,
          button_uz,
          button_ru
        };
        updateMainCategory(token, id, finalObj).then(async (res) => {
          if (res.status === 200) {
            const r = await res.clone().json();
            const newData = this.state.myData.filter((d) => d.id != id);
            const sortedData = [r, ...newData].sort(function (a, b) {
              return a.id - b.id;
            });
            this.setState({
              myData: sortedData,
            });
            setTimeout(() => {
              this.setState({ disableBtn2: false });
              this.onCloseModal();
              toast.success(this.props.translate("Successfully_updated"));
            }, 2000);
          } else {
            console.log("wrong status with main category");
          }
        });
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  // cropping

  handleCancel = () => {
    this.setState({
      openCrop: false,
      blob: "",
      blobURL: "",
    });
  };
  handleDone = () => {
    const cropper = this.cropperRef.current.cropper;
    if (!cropper) return;
    cropper.getCroppedCanvas().toBlob((blob) => {
      const blobURL = URL.createObjectURL(blob);

      this.setState({
        blob,
        blobURL,
        imageChanged: true,
      });
    }, "image/jpeg");

    this.handleCancel();
  };

  handleAttach = () => {
    this.attachPhotoRef.current.click();
  };
  archive = (e, id) => {
    e.preventDefault();
    const data = {
      isArchived: true
    }
  archiveMaincategories(this.state.token, id, data).then((res)=>{
      console.log(res);
      if (res.status == 200) {
        window.location.reload()
      }
  })
  }
  unArchive = (e, id) => {
    e.preventDefault();
    const data = {
      isArchived: false
    }
  archiveMaincategories(this.state.token, id, data).then((res)=>{
      console.log(res)
      if (res.status == 200) {
        window.location.reload()
      }
  })
  }

  handleAttachComplete = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    if (file) {
      var extension = file.name.split(".").pop().toLowerCase();
      var isSuccess = this.state.fileTypes.indexOf(extension) > -1;
    }
    if (isSuccess) {
      reader.onloadend = async () => {
        const [width, height] = await readImageSize(file);

        this.setState({
          openCrop: true,
          file,
          fileURL: URL.createObjectURL(file),
          width,
          height,
        });
        this.attachPhotoRef.current.value = "";
      };
      reader.readAsDataURL(file);
    } else {
      console.log("error image");
    }
  };

  render() {
    const {translate, locale} = this.props
    const { open, openCrop, width, height, fileURL, blobURL } = this.state;
    const { width: fitWidth, height: fitHeight } = getFitSize(
      { width, height },
      400,
      true
    );
    const columns = [
      {
        Header: "ID",
        accessor: "id",
        style: {
          textAlign: "center",
        },
      },
      {
        Header: translate("name"),
        accessor: locale === 'en' ? "name_uz" : "name_ru",
        style: {
          textAlign: "center",
        },
      },
      {
        Header: translate("Description"),
        accessor: locale === "en" ? "description_uz" : "description_ru",
        style: {
          textAlign: "center",
        },
        sortable: false,
        filterable: false,
      },
      {
        id: "imageId",
        Header: translate("wallpaper"),
        accessor: (row) => {
          return (
            <img
              style={{ height: "65px", width: "130px" }}
              src={row.image ? row.image.imageUrl : man}
              alt="main-category-img"
            />
          );
        },
        style: {
          textAlign: "center",
        },
        sortable: false,
        filterable: false,
      },

      {
        id: "createdAt",
        Header: translate("Time_created"),
        accessor: (row) => {
          return moment(row.createdAt).format("DD/MM/YYYY");
        },
        style: {
          textAlign: "center",
        },
        filterable: false,
      },
      {
        id: "updatedAt",
        Header: translate("Time_updated"),
        accessor: (row) => {
          return moment(row.updatedAt).format("DD/MM/YYYY");
        },
        style: {
          textAlign: "center",
        },
        filterable: false,
      },
      {
        Header: "Archived",
        Cell: (row) => (
          <div>
            {!row.original.isArchived ? <span>
              not archived
            </span> : <span>
              archived
            </span>}
          </div>
        ),
        style: {
          textAlign: "center",
        },
        sortable: false,
        filterable: false,
      },
      {
        Header: <b>Action</b>,
        Cell: (row) => (
          <div>
            {!row.original.isArchived ? <span
              onClick={() => {
                console.log(row.original);
                this.setState({
                  id: row.original.id,
                  name_uz: row.original.name_uz,
                  description_uz: row.original.description_uz
                    ? row.original.description_uz
                    : "",
                  name_ru: row.original.name_ru,
                  description_ru: row.original.description_ru
                    ? row.original.description_ru
                    : "",
                  imageId: row.original.imageId,
                  blobURL: row.original.image
                    ? row.original.image.imageUrl
                    : "",
                  button_uz: row.original.button_uz ? row.original.button_uz : "",
                  button_ru: row.original.button_ru ? row.original.button_ru : "",

                });
                this.onOpenModal("edit");
              }}
            >
              <i
                className="fa fa-pencil"
                style={{
                  width: 35,
                  fontSize: 20,
                  padding: 11,
                  color: "rgb(40, 167, 69)",
                }}
              ></i>
            </span> : ""}
            
            <span
                     onClick={(e) => {!row.original.isArchived ?
                      this.archive(e, row.original.id) : this.unArchive(e, row.original.id)
                    }}>
                <i
                  className="fa fa-file-archive-o"
                  style={{
                    width: 35,
                    fontSize: 20,
                    padding: 10,
                    color: "rgb(40, 167, 69)",
                  }}
                ></i>
              </span>
          </div>
        ),
        style: {
          textAlign: "center",
          cursor: "pointer",
        },
        sortable: false,
        filterable: false,
      },
    ];

    return (
      <Fragment>
        <Breadcrumb title={translate("Main_category")} parent={translate("Product_classifications")} />
        {/* <!-- Container-fluid starts--> */}
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <h5>{translate("Main_category_products")}</h5>
                </div>
                <div className="card-body">
                  <div className="btn-popup pull-right">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => this.onOpenModal("add")}
                      data-toggle="modal"
                      data-original-title="test"
                      data-target="#exampleModal"
                    >
                      {translate("add_main_category")}
                    </button>
                    <Modal
                      open={open}
                      onClose={this.onCloseModal}
                      closeOnOverlayClick={false}
                    >
                      <div className="modal-header">
                        <h5
                          className="modal-title f-w-600"
                          id="exampleModalLabel2"
                        >
                          {this.state.action == "add"
                            ? translate("add_main_category")
                            : translate("update_main_category")}
                        </h5>
                      </div>
                      <div className="modal-body">
                        <form>
                          <div className="form-group">
                            <label className="col-form-label">
                              {translate("main_category_name")}:
                            </label>
                            <input
                              type="text"
                              name="name_uz"
                              placeholder={
                                this.state.action == "edit"
                                  ? this.state.name_uz
                                  : translate("In_uzbek")
                              }
                              value={this.state.name_uz}
                              onChange={this.handleInputs}
                              className="form-control"
                              onBlur={() =>
                                this.validator.showMessageFor("name_uz")
                              }
                            />
                            {this.validator.message(
                              "name_uz",
                              this.state.name_uz,
                              "required"
                            )}
                            <label className="col-form-label">
                            {translate("main_category_name")}:
                            </label>
                            <input
                              type="text"
                              name="name_ru"
                              placeholder={
                                this.state.action == "edit"
                                  ? this.state.name_uz
                                  : translate("In_russian")
                              }
                              value={this.state.name_ru}
                              onChange={this.handleInputs}
                              className="form-control"
                              onBlur={() =>
                                this.validator.showMessageFor("name_ru")
                              }
                            />
                            {this.validator.message(
                              "name_ru",
                              this.state.name_ru,
                              "required"
                            )}
                            <label className="col-form-label">{translate("Description")}:</label>
                            <textarea
                              rows="2"
                              className="form-control"
                              name="description_uz"
                              placeholder={
                                this.state.action == "edit"
                                  ? this.state.description_uz
                                  : translate("In_uzbek")
                              }
                              value={this.state.description_uz}
                              onChange={this.handleInputs}
                              onBlur={() =>
                                this.validator.showMessageFor("description_uz")
                              }
                            />
                            {this.validator.message(
                              "description_uz",
                              this.state.description_uz,
                              "required"
                            )}
                            <label className="col-form-label">{translate("Description")}:</label>
                            <textarea
                              rows="2"
                              className="form-control"
                              name="description_ru"
                              placeholder={
                                this.state.action == "edit"
                                  ? this.state.description_ru
                                  : translate("In_russian")
                              }
                              value={this.state.description_ru}
                              onChange={this.handleInputs}
                              onBlur={() =>
                                this.validator.showMessageFor("description_ru")
                              }
                            />
                            {this.validator.message(
                              "description_ru",
                              this.state.description_ru,
                              "required"
                            )}
                            <label className="col-form-label">
                               {translate("Button_name")}:
                            </label>
                            <input
                              type="text"
                              name="button_uz"
                              placeholder={
                                this.state.action == "edit"
                                  ? this.state.button_uz
                                  : translate("In_uzbek")
                              }
                              value={this.state.button_uz}
                              onChange={this.handleInputs}
                              className="form-control"
                              onBlur={() =>
                                this.validator.showMessageFor("button_uz")
                              }
                            />
                            {this.validator.message(
                              "button_uz",
                              this.state.button_uz,
                              "required"
                            )}
                             <label className="col-form-label">
                              {translate("Button_name")}:
                            </label>
                            <input
                              type="text"
                              name="button_ru"
                              placeholder={
                                this.state.action == "edit"
                                  ? this.state.button_ru
                                  : translate("In_russian")
                              }
                              value={this.state.button_ru}
                              onChange={this.handleInputs}
                              className="form-control"
                              onBlur={() =>
                                this.validator.showMessageFor("button_ru")
                              }
                            />
                            {this.validator.message(
                              "button_ru",
                              this.state.button_ru,
                              "required"
                            )}


                            <div>
                              <label className="col-form-label">
                                {translate("wallpaper")}:
                              </label>
                              <div className="new-chat-photo">
                                {blobURL ? (
                                  <img
                                    className="new-chat-photo-image-button"
                                    alt=""
                                    src={blobURL}
                                    onClick={this.handleAttach}
                                  />
                                ) : (
                                  <div
                                    className="new-chat-photo-image-button new-chat-photo-button"
                                    onClick={this.handleAttach}
                                  >
                                    <i
                                      className="fa fa-plus"
                                      aria-hidden="true"
                                    ></i>
                                  </div>
                                )}
                              </div>

                              <input
                                style={{ display: "none" }}
                                ref={this.attachPhotoRef}
                                type="file"
                                multiple={false}
                                accept="image/*"
                                onChange={(e) => this.handleAttachComplete(e)}
                                onBlur={() =>
                                  this.validator.showMessageFor(
                                    "mainCategory_img"
                                  )
                                }
                              />
                              {this.validator.message(
                                "mainCategory_img",
                                this.state.blobURL,
                                "required"
                              )}
                              {openCrop && (
                                <div>
                                  <div>
                                    <Cropper
                                      ref={this.cropperRef}
                                      src={fileURL}
                                      style={{
                                        height: fitHeight,
                                        width: fitWidth,
                                      }}
                                      aspectRatio={48 / 17}
                                      cropBoxResizable={false}
                                      viewMode={3}
                                      guides={true}
                                    />
                                  </div>
                                  <div className="crop-btns">
                                    <button onClick={this.handleCancel}>
                                      <i
                                        style={{ color: "red" }}
                                        className="fa fa-close"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                    <button onClick={this.handleDone}>
                                      <i
                                        style={{ color: "#00a075" }}
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </form>
                      </div>
                      <div className="modal-footer">
                        {this.state.action == "add" ? (
                          <button
                            type="button"
                            disabled={this.state.disableBtn1}
                            style={{
                            cursor: this.state.disableBtn1 ? "not-allowed" : "",
                            }}
                            className="btn btn-primary"
                            onClick={(event) => this.addMaincategoryBtn(event)}
                          >
                           <span
                             style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                             }}
                           >
                           <ClipLoader
                             color={"#fff"}
                             loading={this.state.disableBtn1}
                             size={20}
                            />
                             <span className="ml-1">
                               {translate("Add")}
                             </span>
                           </span>

                          </button>
                        ) : (
                          <button
                            type="button"
                            disabled={this.state.disableBtn2}
                            style={{
                            cursor: this.state.disableBtn2 ? "not-allowed" : "",
                            }}
                            className="btn btn-primary"
                            onClick={(event) =>
                              this.updateMaincategoryBtn(event)
                            }
                          >
                            <span
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                            <ClipLoader
                              color={"#fff"}
                              loading={this.state.disableBtn2}
                              size={20}
                            />
                              <span className="ml-1">
                               {translate("Update")}
                              </span>
                            </span>
                          </button>
                        )}
                        <button
                          type="button"
                          className="btn btn-secondary"
                          onClick={() => this.onCloseModal("VaryingMdo")}
                        >
                          {translate("Cancel")}
                        </button>
                      </div>
                    </Modal>
                  </div>
                  <div className="clearfix"></div>
                  <div id="basicScenario" className="product-physical">
                    <ReactTable
                      manual
                      multiSelectOption={false}
                      defaultPageSize={10}
                      pages={this.state.pages}
                      data={this.state.myData}
                      columns={columns}
                      showPagination={true}
                      className="-striped -highlight"
                      onFetchData={this.fetchData}
                      filterable
                      previousText={translate("Previous")}
                      nextText={translate("Next")}
                      pageText={translate("Page")}
                      rowsText={translate("Rows")}
                    />
                    <ToastContainer />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Container-fluid Ends--> */}
      </Fragment>
    );
  }
}


const mapStateToProps = (state) => ({
  locale: state.Intl.locale,
});

export default connect(mapStateToProps)(withTranslate(MainCategory));
