const getUserList = async (token = {}, page = 1, pageSize = 10) => {
  const req = await fetch(
    `${process.env.REACT_APP_API_MEBZONE}/users?page=${page}&size=${pageSize}`,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
  try {
    const usersList = await req;
    return usersList;
  } catch (error) {
    console.log("error", error);
  }
};

const putBlockUser = async (token = {}, userId) => {
  const req = await fetch(
    `${process.env.REACT_APP_API_MEBZONE}/users/${userId}/block`,
    {
      method: "PUT",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json; charset= UTF-8",
        Authorization: "Bearer " + token,
      },
    }
  );
  try {
    const result = await req;
    return result;
  } catch (error) {
    console.log("error", error);
  }
};

const getStatistics = async () => {
  const req = await fetch(`${process.env.REACT_APP_API_MEBZONE}/stats`);
  try {
    const stats = await req.json();
    return stats;
  } catch (error) {
    console.log("error", error);
  }
};

export { getUserList, putBlockUser, getStatistics };
